@use 'assets/utils' as *;

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  z-index: 0;
  color: $bright-gray;
  position: relative;
  background-image: $tertiary-gradient;

  i {
    font-size: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: $dark-gunmetal;
    inset: 2px;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: -1;
  }

  &[disabled] {
    background-color: $dark-gunmetal;
    border: 1px solid $american-blue;
    color: $american-blue;
    background-image: none;
    cursor: default;

    &::before {
      display: none;
    }

    &:active,
    &:hover {
      &::before {
        display: none;
      }
    }
  }

  &.loading {
    position: relative;
    color: transparent;

    &:after {
      content: '';
      @include absolute(0,0,0,0);
      background-image: url("../images/loading.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @include screen('laptop', 'min') {
    &:hover {
      filter: brightness(80%);

      &::before {
        background-color: transparent;
      }
    }

    &:active {
      filter: brightness(50%);

      &:before {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background-image: $tertiary-gradient-transparent;
        border-radius: 10px;
        box-sizing: border-box;
        z-index: -1;
      }
    }
  }

  // COLORS

  &-blue {
    background-image: none;
    background-color: $blue;
    white-space: nowrap;

    &:before {
      display: none;
    }

    i {
      margin-right: 8px;
    }
  }

  &-orange {
    background-image: none;
    background-color: $orange;
    white-space: nowrap;

    &:before {
      display: none;
    }

    i {
      margin-right: 8px;
    }
  }

  &-green {
    background-image: none;
    background-color: $guppie-green;
    white-space: nowrap;
    color: $chinese-black;

    &:before {
      display: none;
    }

    i {
      margin-right: 8px;
    }
  }

  &-red {
    background-image: none;
    background-color: $coral-red;
    white-space: nowrap;
    color: $chinese-black;

    &:before {
      display: none;
    }

    i {
      margin-right: 8px;
    }
  }

  &-white {
    background-image: none;
    background-color: $white;
    white-space: nowrap;
    color: $chinese-black;

    &[disabled] {
      background-color: $white;
      color: $chinese-black;
      filter: brightness(80%);
      opacity: 0.7;
      border: none;
    }

    &:before {
      display: none;
    }

    i {
      margin-right: 8px;
    }
  }

  // STYLE

  &-secondary {
    background-image: none;
    background-color: $american-blue;
    font-size: 14px;
    color: $white;

    &:before {
      display: none;
    }

    i {
      font-size: 20px;
    }
  }

  &-faded {
    background-image: none;
    background-color: $yankee-blue;
    font-size: 14px;
    color: $white;

    &:before {
      display: none;
    }

    i {
      font-size: 20px;
    }
  }

  &-outlined {
    background-image: none;
    background: $dark-gunmetal;
    border: 1px solid $american-blue;
    font-size: 14px;
    color: $white;

    &:before {
      display: none;
    }

    i {
      font-size: 20px;
    }
  }

  // SIZES

  &-icon {
    width: 100%;
    font-size: 14px;
    color: $white;
    padding: 10px;

    &:not(.colored){
      background-image: none;
      background-color: $american-blue;

      &:before {
        display: none;
      }
    }

    &.transparent {
      background: none;
      border: none;
    }

    i {
      margin: 0;
      font-size: 20px;
    }

    &[disabled] {
      border: none;
      color: $white;
      opacity: 0.5;
    }
  }

  &-small {
    padding: 12px;
    font-size: 14px;
  }

  &-large {
    padding: 16px;
    font-size: 16px;
  }

  &-extra-large {
    padding: 18px 36px;
  }

  // POSITION

  &-block {
    width: 100%;
  }

  &-space-between {
    justify-content: space-between;
  }
}