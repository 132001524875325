html,
body {
  @include square(100%);
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.5;
}

h1 {
  font-size: 36px;
  @include screen('tablet', 'max') {
    font-size: 28px;
  }
}

h2 {
  font-size: 24px;
  @include screen('tablet', 'max') {
    font-size: 20px;
  }
}

h3 {
  font-size: 20px;
  @include screen('tablet', 'max') {
    font-size: 17px;
  }
}

a {
  text-decoration: none;
}