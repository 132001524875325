@font-face {
  font-family: 'unicons';
  src: url('/assets/fonts/unicons.ttf?vmfpe') format('truetype'),
  url('/assets/fonts/unicons.woff?vmfpe') format('woff'),
  url('/assets/fonts/unicons.svg?vmfpe#unicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unicons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.u_0-plus:before {
    content: "\e901";
  }

  &.u_3-plus:before {
    content: "\e902";
  }

  &.u_6-plus:before {
    content: "\e903";
  }

  &.u_10-plus:before {
    content: "\e904";
  }

  &.u_12-plus:before {
    content: "\e905";
  }

  &.u_13-plus:before {
    content: "\e906";
  }

  &.u_16-plus:before {
    content: "\e907";
  }

  &.u_17-plus:before {
    content: "\e908";
  }

  &.u_18-plus:before {
    content: "\e909";
  }

  &.u_21-plus:before {
    content: "\e90a";
  }

  &.u_500px:before {
    content: "\e90b";
  }

  &.u_abacus:before {
    content: "\e90c";
  }

  &.u_accessible-icon-alt:before {
    content: "\e90d";
  }

  &.u_adjust-alt:before {
    content: "\e90e";
  }

  &.u_adjust-circle:before {
    content: "\e90f";
  }

  &.u_adjust-half:before {
    content: "\e910";
  }

  &.u_adjust:before {
    content: "\e911";
  }

  &.u_adobe-alt:before {
    content: "\e912";
  }

  &.u_adobe:before {
    content: "\e913";
  }

  &.u_airplay:before {
    content: "\e914";
  }

  &.u_align-alt:before {
    content: "\e915";
  }

  &.u_align-center-alt:before {
    content: "\e916";
  }

  &.u_align-center-h:before {
    content: "\e917";
  }

  &.u_align-center-justify:before {
    content: "\e918";
  }

  &.u_align-center-v:before {
    content: "\e919";
  }

  &.u_align-center:before {
    content: "\e91a";
  }

  &.u_align-justify:before {
    content: "\e91b";
  }

  &.u_align-left-justify:before {
    content: "\e91c";
  }

  &.u_align-left:before {
    content: "\e91d";
  }

  &.u_align-letter-right:before {
    content: "\e91e";
  }

  &.u_align-right-justify:before {
    content: "\e91f";
  }

  &.u_align-right:before {
    content: "\e920";
  }

  &.u_align:before {
    content: "\e921";
  }

  &.u_amazon:before {
    content: "\e922";
  }

  &.u_ambulance:before {
    content: "\e923";
  }

  &.u_analysis:before {
    content: "\e924";
  }

  &.u_analytics:before {
    content: "\e925";
  }

  &.u_anchor:before {
    content: "\e926";
  }

  &.u_android-alt:before {
    content: "\e927";
  }

  &.u_android-phone-slash:before {
    content: "\e928";
  }

  &.u_android:before {
    content: "\e929";
  }

  &.u_angle-double-down:before {
    content: "\e92a";
  }

  &.u_angle-double-left:before {
    content: "\e92b";
  }

  &.u_angle-double-right:before {
    content: "\e92c";
  }

  &.u_angle-double-up:before {
    content: "\e92d";
  }

  &.u_angle-down:before {
    content: "\e92e";
  }

  &.u_angle-left-b:before {
    content: "\e92f";
  }

  &.u_angle-left:before {
    content: "\e930";
  }

  &.u_angle-right-b:before {
    content: "\e931";
  }

  &.u_angle-right:before {
    content: "\e932";
  }

  &.u_angle-up:before {
    content: "\e933";
  }

  &.u_angry:before {
    content: "\e934";
  }

  &.u_ankh:before {
    content: "\e935";
  }

  &.u_annoyed-alt:before {
    content: "\e936";
  }

  &.u_annoyed:before {
    content: "\e937";
  }

  &.u_apple-alt:before {
    content: "\e938";
  }

  &.u_apple:before {
    content: "\e939";
  }

  &.u_apps:before {
    content: "\e93a";
  }

  &.u_archive-alt:before {
    content: "\e93b";
  }

  &.u_archive:before {
    content: "\e93c";
  }

  &.u_archway:before {
    content: "\e93d";
  }

  &.u_arrow-break:before {
    content: "\e93e";
  }

  &.u_arrow-circle-down:before {
    content: "\e93f";
  }

  &.u_arrow-circle-left:before {
    content: "\e940";
  }

  &.u_arrow-circle-right:before {
    content: "\e941";
  }

  &.u_arrow-circle-up:before {
    content: "\e942";
  }

  &.u_arrow-compress-h:before {
    content: "\e943";
  }

  &.u_arrow-down-left:before {
    content: "\e944";
  }

  &.u_arrow-down-right:before {
    content: "\e945";
  }

  &.u_arrow-down:before {
    content: "\e946";
  }

  &.u_arrow-from-right:before {
    content: "\e947";
  }

  &.u_arrow-from-top:before {
    content: "\e948";
  }

  &.u_arrow-growth:before {
    content: "\e949";
  }

  &.u_arrow-left:before {
    content: "\e94a";
  }

  &.u_arrow-random:before {
    content: "\e94b";
  }

  &.u_arrow-resize-diagonal:before {
    content: "\e94c";
  }

  &.u_arrow-right:before {
    content: "\e94d";
  }

  &.u_arrow-to-bottom:before {
    content: "\e94e";
  }

  &.u_arrow-to-right:before {
    content: "\e94f";
  }

  &.u_arrow-up-left:before {
    content: "\e950";
  }

  &.u_arrow-up-right:before {
    content: "\e951";
  }

  &.u_arrow-up:before {
    content: "\e952";
  }

  &.u_arrow:before {
    content: "\e953";
  }

  &.u_arrows-h-alt:before {
    content: "\e954";
  }

  &.u_arrows-h:before {
    content: "\e955";
  }

  &.u_arrows-left-down:before {
    content: "\e956";
  }

  &.u_arrows-maximize:before {
    content: "\e957";
  }

  &.u_arrows-merge:before {
    content: "\e958";
  }

  &.u_arrows-resize-h:before {
    content: "\e959";
  }

  &.u_arrows-resize-v:before {
    content: "\e95a";
  }

  &.u_arrows-resize:before {
    content: "\e95b";
  }

  &.u_arrows-right-down:before {
    content: "\e95c";
  }

  &.u_arrows-shrink-h:before {
    content: "\e95d";
  }

  &.u_arrows-shrink-v:before {
    content: "\e95e";
  }

  &.u_arrows-up-right:before {
    content: "\e95f";
  }

  &.u_arrows-v-alt:before {
    content: "\e960";
  }

  &.u_arrows-v:before {
    content: "\e961";
  }

  &.u_assistive-listening-systems:before {
    content: "\e962";
  }

  &.u_asterisk:before {
    content: "\e963";
  }

  &.u_at:before {
    content: "\e964";
  }

  &.u_atom:before {
    content: "\e965";
  }

  &.u_auto-flash:before {
    content: "\e966";
  }

  &.u_award-alt:before {
    content: "\e967";
  }

  &.u_award:before {
    content: "\e968";
  }

  &.u_baby-carriage:before {
    content: "\e969";
  }

  &.u_backpack:before {
    content: "\e96a";
  }

  &.u_backspace:before {
    content: "\e96b";
  }

  &.u_backward:before {
    content: "\e96c";
  }

  &.u_bag-alt:before {
    content: "\e96d";
  }

  &.u_bag-slash:before {
    content: "\e96e";
  }

  &.u_bag:before {
    content: "\e96f";
  }

  &.u_balance-scale:before {
    content: "\e970";
  }

  &.u_ban:before {
    content: "\e971";
  }

  &.u_band-aid:before {
    content: "\e972";
  }

  &.u_bars:before {
    content: "\e973";
  }

  &.u_baseball-ball:before {
    content: "\e974";
  }

  &.u_basketball-hoop:before {
    content: "\e975";
  }

  &.u_basketball:before {
    content: "\e976";
  }

  &.u_bath:before {
    content: "\e977";
  }

  &.u_battery-bolt:before {
    content: "\e978";
  }

  &.u_battery-empty:before {
    content: "\e979";
  }

  &.u_bed-double:before {
    content: "\e97a";
  }

  &.u_bed:before {
    content: "\e97b";
  }

  &.u_behance-alt:before {
    content: "\e97c";
  }

  &.u_behance:before {
    content: "\e97d";
  }

  &.u_bell-school:before {
    content: "\e97e";
  }

  &.u_bell-slash:before {
    content: "\e97f";
  }

  &.u_bell:before {
    content: "\e980";
  }

  &.u_bill:before {
    content: "\e981";
  }

  &.u_bitcoin-alt:before {
    content: "\e982";
  }

  &.u_bitcoin-circle:before {
    content: "\e983";
  }

  &.u_bitcoin-sign:before {
    content: "\e984";
  }

  &.u_bitcoin:before {
    content: "\e985";
  }

  &.u_black-berry:before {
    content: "\e986";
  }

  &.u_blogger-alt:before {
    content: "\e987";
  }

  &.u_blogger:before {
    content: "\e988";
  }

  &.u_bluetooth-b:before {
    content: "\e989";
  }

  &.u_bold:before {
    content: "\e98a";
  }

  &.u_bolt-alt:before {
    content: "\e98b";
  }

  &.u_bolt-slash:before {
    content: "\e98c";
  }

  &.u_bolt:before {
    content: "\e98d";
  }

  &.u_book-alt:before {
    content: "\e98e";
  }

  &.u_book-medical:before {
    content: "\e98f";
  }

  &.u_book-open:before {
    content: "\e990";
  }

  &.u_book-reader:before {
    content: "\e991";
  }

  &.u_book:before {
    content: "\e992";
  }

  &.u_bookmark-full:before {
    content: "\e993";
  }

  &.u_bookmark:before {
    content: "\e994";
  }

  &.u_books:before {
    content: "\e995";
  }

  &.u_boombox:before {
    content: "\e996";
  }

  &.u_border-alt:before {
    content: "\e997";
  }

  &.u_border-bottom:before {
    content: "\e998";
  }

  &.u_border-clear:before {
    content: "\e999";
  }

  &.u_border-horizontal:before {
    content: "\e99a";
  }

  &.u_border-inner:before {
    content: "\e99b";
  }

  &.u_border-left:before {
    content: "\e99c";
  }

  &.u_border-out:before {
    content: "\e99d";
  }

  &.u_border-right:before {
    content: "\e99e";
  }

  &.u_border-top:before {
    content: "\e99f";
  }

  &.u_border-vertical:before {
    content: "\e9a0";
  }

  &.u_bowling-ball:before {
    content: "\e9a1";
  }

  &.u_box:before {
    content: "\e9a2";
  }

  &.u_brackets-curly:before {
    content: "\e9a3";
  }

  &.u_brain:before {
    content: "\e9a4";
  }

  &.u_briefcase-alt:before {
    content: "\e9a5";
  }

  &.u_briefcase:before {
    content: "\e9a6";
  }

  &.u_bright:before {
    content: "\e9a7";
  }

  &.u_brightness-empty:before {
    content: "\e9a8";
  }

  &.u_brightness-half:before {
    content: "\e9a9";
  }

  &.u_brightness-low:before {
    content: "\e9aa";
  }

  &.u_brightness-minus:before {
    content: "\e9ab";
  }

  &.u_brightness-plus:before {
    content: "\e9ac";
  }

  &.u_brightness:before {
    content: "\e9ad";
  }

  &.u_bring-bottom:before {
    content: "\e9ae";
  }

  &.u_bring-front:before {
    content: "\e9af";
  }

  &.u_browser:before {
    content: "\e9b0";
  }

  &.u_brush-alt:before {
    content: "\e9b1";
  }

  &.u_bug:before {
    content: "\e9b2";
  }

  &.u_building:before {
    content: "\e9b3";
  }

  &.u_bullseye:before {
    content: "\e9b4";
  }

  &.u_bus-alt:before {
    content: "\e9b5";
  }

  &.u_bus-school:before {
    content: "\e9b6";
  }

  &.u_bus:before {
    content: "\e9b7";
  }

  &.u_calculator-alt:before {
    content: "\e9b8";
  }

  &.u_calculator:before {
    content: "\e9b9";
  }

  &.u_calendar-alt:before {
    content: "\e9ba";
  }

  &.u_calendar-slash:before {
    content: "\e9bb";
  }

  &.u_calender:before {
    content: "\e9bc";
  }

  &.u_calling:before {
    content: "\e9bd";
  }

  &.u_camera-change:before {
    content: "\e9be";
  }

  &.u_camera-plus:before {
    content: "\e9bf";
  }

  &.u_camera-slash:before {
    content: "\e9c0";
  }

  &.u_camera:before {
    content: "\e9c1";
  }

  &.u_cancel:before {
    content: "\e9c2";
  }

  &.u_capsule:before {
    content: "\e9c3";
  }

  &.u_capture:before {
    content: "\e9c4";
  }

  &.u_car-sideview:before {
    content: "\e9c5";
  }

  &.u_car-slash:before {
    content: "\e9c6";
  }

  &.u_car-wash:before {
    content: "\e9c7";
  }

  &.u_car:before {
    content: "\e9c8";
  }

  &.u_card-atm:before {
    content: "\e9c9";
  }

  &.u_caret-right:before {
    content: "\e9ca";
  }

  &.u_cell:before {
    content: "\e9cb";
  }

  &.u_celsius:before {
    content: "\e9cc";
  }

  &.u_channel-add:before {
    content: "\e9cd";
  }

  &.u_channel:before {
    content: "\e9ce";
  }

  &.u_chart-bar-alt:before {
    content: "\e9cf";
  }

  &.u_chart-bar:before {
    content: "\e9d0";
  }

  &.u_chart-down:before {
    content: "\e9d1";
  }

  &.u_chart-growth-alt:before {
    content: "\e9d2";
  }

  &.u_chart-growth:before {
    content: "\e9d3";
  }

  &.u_chart-line:before {
    content: "\e9d4";
  }

  &.u_chart-pie-alt:before {
    content: "\e9d5";
  }

  &.u_chart-pie:before {
    content: "\e9d6";
  }

  &.u_chart:before {
    content: "\e9d7";
  }

  &.u_chat-bubble-user:before {
    content: "\e9d8";
  }

  &.u_chat-info:before {
    content: "\e9d9";
  }

  &.u_chat:before {
    content: "\e9da";
  }

  &.u_check-circle:before {
    content: "\e9db";
  }

  &.u_check-square:before {
    content: "\e9dc";
  }

  &.u_check:before {
    content: "\e9dd";
  }

  &.u_circle-layer:before {
    content: "\e9de";
  }

  &.u_circle:before {
    content: "\e9df";
  }

  &.u_circuit:before {
    content: "\e9e0";
  }

  &.u_clapper-board:before {
    content: "\e9e1";
  }

  &.u_clipboard-alt:before {
    content: "\e9e2";
  }

  &.u_clipboard-blank:before {
    content: "\e9e3";
  }

  &.u_clipboard-notes:before {
    content: "\e9e4";
  }

  &.u_clipboard:before {
    content: "\e9e5";
  }

  &.u_clock-eight:before {
    content: "\e9e6";
  }

  &.u_clock-five:before {
    content: "\e9e7";
  }

  &.u_clock-nine:before {
    content: "\e9e8";
  }

  &.u_clock-seven:before {
    content: "\e9e9";
  }

  &.u_clock-ten:before {
    content: "\e9ea";
  }

  &.u_clock-three:before {
    content: "\e9eb";
  }

  &.u_clock-two:before {
    content: "\e9ec";
  }

  &.u_clock:before {
    content: "\e9ed";
  }

  &.u_closed-captioning-slash:before {
    content: "\e9ee";
  }

  &.u_closed-captioning:before {
    content: "\e9ef";
  }

  &.u_cloud-block:before {
    content: "\e9f0";
  }

  &.u_cloud-bookmark:before {
    content: "\e9f1";
  }

  &.u_cloud-check:before {
    content: "\e9f2";
  }

  &.u_cloud-computing:before {
    content: "\e9f3";
  }

  &.u_cloud-data-connection:before {
    content: "\e9f4";
  }

  &.u_cloud-database-tree:before {
    content: "\e9f5";
  }

  &.u_cloud-download:before {
    content: "\e9f6";
  }

  &.u_cloud-drizzle:before {
    content: "\e9f7";
  }

  &.u_cloud-exclamation:before {
    content: "\e9f8";
  }

  &.u_cloud-hail:before {
    content: "\e9f9";
  }

  &.u_cloud-heart:before {
    content: "\e9fa";
  }

  &.u_cloud-info:before {
    content: "\e9fb";
  }

  &.u_cloud-lock:before {
    content: "\e9fc";
  }

  &.u_cloud-meatball:before {
    content: "\e9fd";
  }

  &.u_cloud-moon-hail:before {
    content: "\e9fe";
  }

  &.u_cloud-moon-meatball:before {
    content: "\e9ff";
  }

  &.u_cloud-moon-rain:before {
    content: "\ea00";
  }

  &.u_cloud-moon-showers:before {
    content: "\ea01";
  }

  &.u_cloud-moon:before {
    content: "\ea02";
  }

  &.u_cloud-question:before {
    content: "\ea03";
  }

  &.u_cloud-rain-sun:before {
    content: "\ea04";
  }

  &.u_cloud-rain:before {
    content: "\ea05";
  }

  &.u_cloud-redo:before {
    content: "\ea06";
  }

  &.u_cloud-share:before {
    content: "\ea07";
  }

  &.u_cloud-shield:before {
    content: "\ea08";
  }

  &.u_cloud-showers-alt:before {
    content: "\ea09";
  }

  &.u_cloud-showers-heavy:before {
    content: "\ea0a";
  }

  &.u_cloud-showers:before {
    content: "\ea0b";
  }

  &.u_cloud-slash:before {
    content: "\ea0c";
  }

  &.u_cloud-sun-hail:before {
    content: "\ea0d";
  }

  &.u_cloud-sun-meatball:before {
    content: "\ea0e";
  }

  &.u_cloud-sun-rain-alt:before {
    content: "\ea0f";
  }

  &.u_cloud-sun-rain:before {
    content: "\ea10";
  }

  &.u_cloud-sun-tear:before {
    content: "\ea11";
  }

  &.u_cloud-sun:before {
    content: "\ea12";
  }

  &.u_cloud-times:before {
    content: "\ea13";
  }

  &.u_cloud-unlock:before {
    content: "\ea14";
  }

  &.u_cloud-upload:before {
    content: "\ea15";
  }

  &.u_cloud-wifi:before {
    content: "\ea16";
  }

  &.u_cloud-wind:before {
    content: "\ea17";
  }

  &.u_cloud:before {
    content: "\ea18";
  }

  &.u_clouds:before {
    content: "\ea19";
  }

  &.u_club:before {
    content: "\ea1a";
  }

  &.u_code-branch:before {
    content: "\ea1b";
  }

  &.u_coffee:before {
    content: "\ea1c";
  }

  &.u_cog:before {
    content: "\ea1d";
  }

  &.u_coins:before {
    content: "\ea1e";
  }

  &.u_columns:before {
    content: "\ea1f";
  }

  &.u_comment-add:before {
    content: "\ea20";
  }

  &.u_comment-alt-block:before {
    content: "\ea21";
  }

  &.u_comment-alt-chart-lines:before {
    content: "\ea22";
  }

  &.u_comment-alt-check:before {
    content: "\ea23";
  }

  &.u_comment-alt-dots:before {
    content: "\ea24";
  }

  &.u_comment-alt-download:before {
    content: "\ea25";
  }

  &.u_comment-alt-edit:before {
    content: "\ea26";
  }

  &.u_comment-alt-exclamation:before {
    content: "\ea27";
  }

  &.u_comment-alt-heart:before {
    content: "\ea28";
  }

  &.u_comment-alt-image:before {
    content: "\ea29";
  }

  &.u_comment-alt-info:before {
    content: "\ea2a";
  }

  &.u_comment-alt-lines:before {
    content: "\ea2b";
  }

  &.u_comment-alt-lock:before {
    content: "\ea2c";
  }

  &.u_comment-alt-medical:before {
    content: "\ea2d";
  }

  &.u_comment-alt-message:before {
    content: "\ea2e";
  }

  &.u_comment-alt-notes:before {
    content: "\ea2f";
  }

  &.u_comment-alt-plus:before {
    content: "\ea30";
  }

  &.u_comment-alt-question:before {
    content: "\ea31";
  }

  &.u_comment-alt-redo:before {
    content: "\ea32";
  }

  &.u_comment-alt-search:before {
    content: "\ea33";
  }

  &.u_comment-alt-share:before {
    content: "\ea34";
  }

  &.u_comment-alt-shield:before {
    content: "\ea35";
  }

  &.u_comment-alt-slash:before {
    content: "\ea36";
  }

  &.u_comment-alt-upload:before {
    content: "\ea37";
  }

  &.u_comment-alt-verify:before {
    content: "\ea38";
  }

  &.u_comment-alt:before {
    content: "\ea39";
  }

  &.u_comment-block:before {
    content: "\ea3a";
  }

  &.u_comment-chart-line:before {
    content: "\ea3b";
  }

  &.u_comment-check:before {
    content: "\ea3c";
  }

  &.u_comment-dots:before {
    content: "\ea3d";
  }

  &.u_comment-download:before {
    content: "\ea3e";
  }

  &.u_comment-edit:before {
    content: "\ea3f";
  }

  &.u_comment-exclamation:before {
    content: "\ea40";
  }

  &.u_comment-heart:before {
    content: "\ea41";
  }

  &.u_comment-image:before {
    content: "\ea42";
  }

  &.u_comment-info-alt:before {
    content: "\ea43";
  }

  &.u_comment-info:before {
    content: "\ea44";
  }

  &.u_comment-lines:before {
    content: "\ea45";
  }

  &.u_comment-lock:before {
    content: "\ea46";
  }

  &.u_comment-medical:before {
    content: "\ea47";
  }

  &.u_comment-message:before {
    content: "\ea48";
  }

  &.u_comment-notes:before {
    content: "\ea49";
  }

  &.u_comment-plus:before {
    content: "\ea4a";
  }

  &.u_comment-question:before {
    content: "\ea4b";
  }

  &.u_comment-redo:before {
    content: "\ea4c";
  }

  &.u_comment-search:before {
    content: "\ea4d";
  }

  &.u_comment-share:before {
    content: "\ea4e";
  }

  &.u_comment-shield:before {
    content: "\ea4f";
  }

  &.u_comment-slash:before {
    content: "\ea50";
  }

  &.u_comment-upload:before {
    content: "\ea51";
  }

  &.u_comment-verify:before {
    content: "\ea52";
  }

  &.u_comment:before {
    content: "\ea53";
  }

  &.u_comments-alt:before {
    content: "\ea54";
  }

  &.u_comments:before {
    content: "\ea55";
  }

  &.u_compact-disc:before {
    content: "\ea56";
  }

  &.u_comparison:before {
    content: "\ea57";
  }

  &.u_compass:before {
    content: "\ea58";
  }

  &.u_compress-alt-left:before {
    content: "\ea59";
  }

  &.u_compress-alt:before {
    content: "\ea5a";
  }

  &.u_compress-arrows:before {
    content: "\ea5b";
  }

  &.u_compress-lines:before {
    content: "\ea5c";
  }

  &.u_compress-point:before {
    content: "\ea5d";
  }

  &.u_compress-v:before {
    content: "\ea5e";
  }

  &.u_compress:before {
    content: "\ea5f";
  }

  &.u_confused:before {
    content: "\ea60";
  }

  &.u_constructor:before {
    content: "\ea61";
  }

  &.u_copy-alt:before {
    content: "\ea62";
  }

  &.u_copy-landscape:before {
    content: "\ea63";
  }

  &.u_copy:before {
    content: "\ea64";
  }

  &.u_copyright:before {
    content: "\ea65";
  }

  &.u_corner-down-left:before {
    content: "\ea66";
  }

  &.u_corner-down-right-alt:before {
    content: "\ea67";
  }

  &.u_corner-down-right:before {
    content: "\ea68";
  }

  &.u_corner-left-down:before {
    content: "\ea69";
  }

  &.u_corner-right-down:before {
    content: "\ea6a";
  }

  &.u_corner-up-left-alt:before {
    content: "\ea6b";
  }

  &.u_corner-up-left:before {
    content: "\ea6c";
  }

  &.u_corner-up-right-alt:before {
    content: "\ea6d";
  }

  &.u_corner-up-right:before {
    content: "\ea6e";
  }

  &.u_create-dashboard:before {
    content: "\ea6f";
  }

  &.u_creative-commons-pd:before {
    content: "\ea70";
  }

  &.u_credit-card-search:before {
    content: "\ea71";
  }

  &.u_credit-card:before {
    content: "\ea72";
  }

  &.u_crockery:before {
    content: "\ea73";
  }

  &.u_crop-alt-rotate-left:before {
    content: "\ea74";
  }

  &.u_crop-alt-rotate-right:before {
    content: "\ea75";
  }

  &.u_crop-alt:before {
    content: "\ea76";
  }

  &.u_crosshair-alt:before {
    content: "\ea77";
  }

  &.u_crosshair:before {
    content: "\ea78";
  }

  &.u_crosshairs:before {
    content: "\ea79";
  }

  &.u_cube:before {
    content: "\ea7a";
  }

  &.u_dashboard:before {
    content: "\ea7b";
  }

  &.u_data-sharing:before {
    content: "\ea7c";
  }

  &.u_database-alt:before {
    content: "\ea7d";
  }

  &.u_database:before {
    content: "\ea7e";
  }

  &.u_desert:before {
    content: "\ea7f";
  }

  &.u_desktop-alt-slash:before {
    content: "\ea80";
  }

  &.u_desktop-alt:before {
    content: "\ea81";
  }

  &.u_desktop-cloud-alt:before {
    content: "\ea82";
  }

  &.u_desktop-slash:before {
    content: "\ea83";
  }

  &.u_desktop:before {
    content: "\ea84";
  }

  &.u_dialpad-alt:before {
    content: "\ea85";
  }

  &.u_dialpad:before {
    content: "\ea86";
  }

  &.u_diamond:before {
    content: "\ea87";
  }

  &.u_diary-alt:before {
    content: "\ea88";
  }

  &.u_diary:before {
    content: "\ea89";
  }

  &.u_dice-five:before {
    content: "\ea8a";
  }

  &.u_dice-four:before {
    content: "\ea8b";
  }

  &.u_dice-one:before {
    content: "\ea8c";
  }

  &.u_dice-six:before {
    content: "\ea8d";
  }

  &.u_dice-three:before {
    content: "\ea8e";
  }

  &.u_dice-two:before {
    content: "\ea8f";
  }

  &.u_direction:before {
    content: "\ea90";
  }

  &.u_directions:before {
    content: "\ea91";
  }

  &.u_dizzy-meh:before {
    content: "\ea92";
  }

  &.u_dna:before {
    content: "\ea93";
  }

  &.u_document-info:before {
    content: "\ea94";
  }

  &.u_document-layout-center:before {
    content: "\ea95";
  }

  &.u_document-layout-left:before {
    content: "\ea96";
  }

  &.u_document-layout-right:before {
    content: "\ea97";
  }

  &.u_dollar-alt:before {
    content: "\ea98";
  }

  &.u_dollar-sign-alt:before {
    content: "\ea99";
  }

  &.u_dollar-sign:before {
    content: "\ea9a";
  }

  &.u_download-alt:before {
    content: "\ea9b";
  }

  &.u_dribbble:before {
    content: "\ea9c";
  }

  &.u_drill:before {
    content: "\ea9d";
  }

  &.u_dropbox:before {
    content: "\ea9e";
  }

  &.u_dumbbell:before {
    content: "\ea9f";
  }

  &.u_ear:before {
    content: "\eaa0";
  }

  &.u_edit-alt:before {
    content: "\eaa1";
  }

  &.u_edit:before {
    content: "\eaa2";
  }

  &.u_ellipsis-h:before {
    content: "\eaa3";
  }

  &.u_ellipsis-v:before {
    content: "\eaa4";
  }

  &.u_emoji:before {
    content: "\eaa5";
  }

  &.u_enter:before {
    content: "\eaa6";
  }

  &.u_envelope-add:before {
    content: "\eaa7";
  }

  &.u_envelope-alt:before {
    content: "\eaa8";
  }

  &.u_envelope-block:before {
    content: "\eaa9";
  }

  &.u_envelope-bookmark:before {
    content: "\eaaa";
  }

  &.u_envelope-check:before {
    content: "\eaab";
  }

  &.u_envelope-download-alt:before {
    content: "\eaac";
  }

  &.u_envelope-download:before {
    content: "\eaad";
  }

  &.u_envelope-edit:before {
    content: "\eaae";
  }

  &.u_envelope-exclamation:before {
    content: "\eaaf";
  }

  &.u_envelope-heart:before {
    content: "\eab0";
  }

  &.u_envelope-info:before {
    content: "\eab1";
  }

  &.u_envelope-lock:before {
    content: "\eab2";
  }

  &.u_envelope-minus:before {
    content: "\eab3";
  }

  &.u_envelope-open:before {
    content: "\eab4";
  }

  &.u_envelope-question:before {
    content: "\eab5";
  }

  &.u_envelope-receive:before {
    content: "\eab6";
  }

  &.u_envelope-redo:before {
    content: "\eab7";
  }

  &.u_envelope-search:before {
    content: "\eab8";
  }

  &.u_envelope-send:before {
    content: "\eab9";
  }

  &.u_envelope-share:before {
    content: "\eaba";
  }

  &.u_envelope-shield:before {
    content: "\eabb";
  }

  &.u_envelope-star:before {
    content: "\eabc";
  }

  &.u_envelope-times:before {
    content: "\eabd";
  }

  &.u_envelope-upload-alt:before {
    content: "\eabe";
  }

  &.u_envelope-upload:before {
    content: "\eabf";
  }

  &.u_envelope:before {
    content: "\eac0";
  }

  &.u_envelopes:before {
    content: "\eac1";
  }

  &.u_equal-circle:before {
    content: "\eac2";
  }

  &.u_euro-circle:before {
    content: "\eac3";
  }

  &.u_euro:before {
    content: "\eac4";
  }

  &.u_exchange-alt:before {
    content: "\eac5";
  }

  &.u_exchange:before {
    content: "\eac6";
  }

  &.u_exclamation-circle:before {
    content: "\eac7";
  }

  &.u_exclamation-octagon:before {
    content: "\eac8";
  }

  &.u_exclamation-triangle:before {
    content: "\eac9";
  }

  &.u_exclude:before {
    content: "\eaca";
  }

  &.u_exit:before {
    content: "\eacb";
  }

  &.u_expand-alt:before {
    content: "\eacc";
  }

  &.u_expand-arrows-alt:before {
    content: "\eacd";
  }

  &.u_expand-arrows:before {
    content: "\eace";
  }

  &.u_expand-from-corner:before {
    content: "\eacf";
  }

  &.u_expand-left:before {
    content: "\ead0";
  }

  &.u_expand-right:before {
    content: "\ead1";
  }

  &.u_export:before {
    content: "\ead2";
  }

  &.u_exposure-alt:before {
    content: "\ead3";
  }

  &.u_exposure-increase:before {
    content: "\ead4";
  }

  &.u_external-link-alt:before {
    content: "\ead5";
  }

  &.u_eye-slash:before {
    content: "\ead6";
  }

  &.u_eye:before {
    content: "\ead7";
  }

  &.u_facebook-f:before {
    content: "\ead8";
  }

  &.u_facebook-messenger-alt:before {
    content: "\ead9";
  }

  &.u_facebook-messenger:before {
    content: "\eada";
  }

  &.u_facebook:before {
    content: "\eadb";
  }

  &.u_fahrenheit:before {
    content: "\eadc";
  }

  &.u_fast-mail-alt:before {
    content: "\eadd";
  }

  &.u_fast-mail:before {
    content: "\eade";
  }

  &.u_favorite:before {
    content: "\eadf";
  }

  &.u_feedback:before {
    content: "\eae0";
  }

  &.u_file-alt:before {
    content: "\eae1";
  }

  &.u_file-blank:before {
    content: "\eae2";
  }

  &.u_file-block-alt:before {
    content: "\eae3";
  }

  &.u_file-bookmark-alt:before {
    content: "\eae4";
  }

  &.u_file-check-alt:before {
    content: "\eae5";
  }

  &.u_file-check:before {
    content: "\eae6";
  }

  &.u_file-contract-dollar:before {
    content: "\eae7";
  }

  &.u_file-copy-alt:before {
    content: "\eae8";
  }

  &.u_file-download-alt:before {
    content: "\eae9";
  }

  &.u_file-download:before {
    content: "\eaea";
  }

  &.u_file-edit-alt:before {
    content: "\eaeb";
  }

  &.u_file-exclamation-alt:before {
    content: "\eaec";
  }

  &.u_file-exclamation:before {
    content: "\eaed";
  }

  &.u_file-export:before {
    content: "\eaee";
  }

  &.u_file-heart:before {
    content: "\eaef";
  }

  &.u_file-import:before {
    content: "\eaf0";
  }

  &.u_file-info-alt:before {
    content: "\eaf1";
  }

  &.u_file-landscape-alt:before {
    content: "\eaf2";
  }

  &.u_file-landscape:before {
    content: "\eaf3";
  }

  &.u_file-lanscape-slash:before {
    content: "\eaf4";
  }

  &.u_file-lock-alt:before {
    content: "\eaf5";
  }

  &.u_file-medical-alt:before {
    content: "\eaf6";
  }

  &.u_file-medical:before {
    content: "\eaf7";
  }

  &.u_file-minus-alt:before {
    content: "\eaf8";
  }

  &.u_file-minus:before {
    content: "\eaf9";
  }

  &.u_file-network:before {
    content: "\eafa";
  }

  &.u_file-plus-alt:before {
    content: "\eafb";
  }

  &.u_file-plus:before {
    content: "\eafc";
  }

  &.u_file-question-alt:before {
    content: "\eafd";
  }

  &.u_file-question:before {
    content: "\eafe";
  }

  &.u_file-redo-alt:before {
    content: "\eaff";
  }

  &.u_file-search-alt:before {
    content: "\eb00";
  }

  &.u_file-share-alt:before {
    content: "\eb01";
  }

  &.u_file-shield-alt:before {
    content: "\eb02";
  }

  &.u_file-slash:before {
    content: "\eb03";
  }

  &.u_file-times-alt:before {
    content: "\eb04";
  }

  &.u_file-times:before {
    content: "\eb05";
  }

  &.u_file-upload-alt:before {
    content: "\eb06";
  }

  &.u_file-upload:before {
    content: "\eb07";
  }

  &.u_file:before {
    content: "\eb08";
  }

  &.u_files-landscapes-alt:before {
    content: "\eb09";
  }

  &.u_files-landscapes:before {
    content: "\eb0a";
  }

  &.u_film:before {
    content: "\eb0b";
  }

  &.u_filter-slash:before {
    content: "\eb0c";
  }

  &.u_filter:before {
    content: "\eb0d";
  }

  &.u_fire:before {
    content: "\eb0e";
  }

  &.u_flask-potion:before {
    content: "\eb0f";
  }

  &.u_flask:before {
    content: "\eb10";
  }

  &.u_flip-h-alt:before {
    content: "\eb11";
  }

  &.u_flip-h:before {
    content: "\eb12";
  }

  &.u_flip-v-alt:before {
    content: "\eb13";
  }

  &.u_flip-v:before {
    content: "\eb14";
  }

  &.u_flower:before {
    content: "\eb15";
  }

  &.u_focus-add:before {
    content: "\eb16";
  }

  &.u_focus-target:before {
    content: "\eb17";
  }

  &.u_focus:before {
    content: "\eb18";
  }

  &.u_folder-check:before {
    content: "\eb19";
  }

  &.u_folder-download:before {
    content: "\eb1a";
  }

  &.u_folder-exclamation:before {
    content: "\eb1b";
  }

  &.u_folder-heart:before {
    content: "\eb1c";
  }

  &.u_folder-info:before {
    content: "\eb1d";
  }

  &.u_folder-lock:before {
    content: "\eb1e";
  }

  &.u_folder-medical:before {
    content: "\eb1f";
  }

  &.u_folder-minus:before {
    content: "\eb20";
  }

  &.u_folder-network:before {
    content: "\eb21";
  }

  &.u_folder-open:before {
    content: "\eb22";
  }

  &.u_folder-plus:before {
    content: "\eb23";
  }

  &.u_folder-question:before {
    content: "\eb24";
  }

  &.u_folder-slash:before {
    content: "\eb25";
  }

  &.u_folder-times:before {
    content: "\eb26";
  }

  &.u_folder-upload:before {
    content: "\eb27";
  }

  &.u_folder:before {
    content: "\eb28";
  }

  &.u_football-american:before {
    content: "\eb29";
  }

  &.u_football-ball:before {
    content: "\eb2a";
  }

  &.u_football:before {
    content: "\eb2b";
  }

  &.u_forecastcloud-moon-tear:before {
    content: "\eb2c";
  }

  &.u_forwaded-call:before {
    content: "\eb2d";
  }

  &.u_forward:before {
    content: "\eb2e";
  }

  &.u_frown:before {
    content: "\eb2f";
  }

  &.u_game-structure:before {
    content: "\eb30";
  }

  &.u_gift:before {
    content: "\eb31";
  }

  &.u_github-alt:before {
    content: "\eb32";
  }

  &.u_github:before {
    content: "\eb33";
  }

  &.u_glass-martini-alt-slash:before {
    content: "\eb34";
  }

  &.u_glass-martini-alt:before {
    content: "\eb35";
  }

  &.u_glass-martini:before {
    content: "\eb36";
  }

  &.u_glass-tea:before {
    content: "\eb37";
  }

  &.u_glass:before {
    content: "\eb38";
  }

  &.u_globe:before {
    content: "\eb39";
  }

  &.u_gold:before {
    content: "\eb3a";
  }

  &.u_golf-ball:before {
    content: "\eb3b";
  }

  &.u_google-drive-alt:before {
    content: "\eb3c";
  }

  &.u_google-drive:before {
    content: "\eb3d";
  }

  &.u_google-hangouts-alt:before {
    content: "\eb3e";
  }

  &.u_google-hangouts:before {
    content: "\eb3f";
  }

  &.u_google-play:before {
    content: "\eb40";
  }

  &.u_google:before {
    content: "\eb41";
  }

  &.u_graduation-cap:before {
    content: "\eb42";
  }

  &.u_graph-bar:before {
    content: "\eb43";
  }

  &.u_grid:before {
    content: "\eb44";
  }

  &.u_grids:before {
    content: "\eb45";
  }

  &.u_grin-tongue-wink-alt:before {
    content: "\eb46";
  }

  &.u_grin-tongue-wink:before {
    content: "\eb47";
  }

  &.u_grin:before {
    content: "\eb48";
  }

  &.u_grip-horizontal-line:before {
    content: "\eb49";
  }

  &.u_hard-hat:before {
    content: "\eb4a";
  }

  &.u_hdd:before {
    content: "\eb4b";
  }

  &.u_headphones-alt:before {
    content: "\eb4c";
  }

  &.u_headphones:before {
    content: "\eb4d";
  }

  &.u_heart-alt:before {
    content: "\eb4e";
  }

  &.u_heart-break:before {
    content: "\eb4f";
  }

  &.u_heart-medical:before {
    content: "\eb50";
  }

  &.u_heart-rate:before {
    content: "\eb51";
  }

  &.u_heart-sign:before {
    content: "\eb52";
  }

  &.u_heart:before {
    content: "\eb53";
  }

  &.u_heartbeat:before {
    content: "\eb54";
  }

  &.u_history-alt:before {
    content: "\eb55";
  }

  &.u_history:before {
    content: "\eb56";
  }

  &.u_home-alt:before {
    content: "\eb57";
  }

  &.u_home:before {
    content: "\eb58";
  }

  &.u_horizontal-align-center:before {
    content: "\eb59";
  }

  &.u_horizontal-align-left:before {
    content: "\eb5a";
  }

  &.u_horizontal-align-right:before {
    content: "\eb5b";
  }

  &.u_horizontal-distribution-center:before {
    content: "\eb5c";
  }

  &.u_horizontal-distribution-left:before {
    content: "\eb5d";
  }

  &.u_horizontal-distribution-right:before {
    content: "\eb5e";
  }

  &.u_hourglass:before {
    content: "\eb5f";
  }

  &.u_html3-alt:before {
    content: "\eb60";
  }

  &.u_html3:before {
    content: "\eb61";
  }

  &.u_hunting:before {
    content: "\eb62";
  }

  &.u_image-alt-slash:before {
    content: "\eb63";
  }

  &.u_image-block:before {
    content: "\eb64";
  }

  &.u_image-broken:before {
    content: "\eb65";
  }

  &.u_image-check:before {
    content: "\eb66";
  }

  &.u_image-download:before {
    content: "\eb67";
  }

  &.u_image-edit:before {
    content: "\eb68";
  }

  &.u_image-lock:before {
    content: "\eb69";
  }

  &.u_image-minus:before {
    content: "\eb6a";
  }

  &.u_image-plus:before {
    content: "\eb6b";
  }

  &.u_image-question:before {
    content: "\eb6c";
  }

  &.u_image-redo:before {
    content: "\eb6d";
  }

  &.u_image-resize-landscape:before {
    content: "\eb6e";
  }

  &.u_image-resize-square:before {
    content: "\eb6f";
  }

  &.u_image-search:before {
    content: "\eb70";
  }

  &.u_image-share:before {
    content: "\eb71";
  }

  &.u_image-shield:before {
    content: "\eb72";
  }

  &.u_image-slash:before {
    content: "\eb73";
  }

  &.u_image-times:before {
    content: "\eb74";
  }

  &.u_image-upload:before {
    content: "\eb75";
  }

  &.u_image-v:before {
    content: "\eb76";
  }

  &.u_image:before {
    content: "\eb77";
  }

  &.u_images:before {
    content: "\eb78";
  }

  &.u_import:before {
    content: "\eb79";
  }

  &.u_incoming-call:before {
    content: "\eb7a";
  }

  &.u_info-circle:before {
    content: "\eb7b";
  }

  &.u_instagram-alt:before {
    content: "\eb7c";
  }

  &.u_instagram:before {
    content: "\eb7d";
  }

  &.u_intercom-alt:before {
    content: "\eb7e";
  }

  &.u_intercom:before {
    content: "\eb7f";
  }

  &.u_invoice:before {
    content: "\eb80";
  }

  &.u_italic:before {
    content: "\eb81";
  }

  &.u_jackhammer:before {
    content: "\eb82";
  }

  &.u_java-script:before {
    content: "\eb83";
  }

  &.u_kayak:before {
    content: "\eb84";
  }

  &.u_key-skeleton-alt:before {
    content: "\eb85";
  }

  &.u_key-skeleton:before {
    content: "\eb86";
  }

  &.u_keyboard-alt:before {
    content: "\eb87";
  }

  &.u_keyboard-hide:before {
    content: "\eb88";
  }

  &.u_keyboard-show:before {
    content: "\eb89";
  }

  &.u_keyboard:before {
    content: "\eb8a";
  }

  &.u_keyhole-circle:before {
    content: "\eb8b";
  }

  &.u_keyhole-square-full:before {
    content: "\eb8c";
  }

  &.u_keyhole-square:before {
    content: "\eb8d";
  }

  &.u_kid:before {
    content: "\eb8e";
  }

  &.u_label-alt:before {
    content: "\eb8f";
  }

  &.u_label:before {
    content: "\eb90";
  }

  &.u_lamp:before {
    content: "\eb91";
  }

  &.u_laptop-cloud:before {
    content: "\eb92";
  }

  &.u_laptop-connection:before {
    content: "\eb93";
  }

  &.u_laptop:before {
    content: "\eb94";
  }

  &.u_laughing:before {
    content: "\eb95";
  }

  &.u_layer-group-slash:before {
    content: "\eb96";
  }

  &.u_layer-group:before {
    content: "\eb97";
  }

  &.u_layers-alt:before {
    content: "\eb98";
  }

  &.u_layers-slash:before {
    content: "\eb99";
  }

  &.u_layers:before {
    content: "\eb9a";
  }

  &.u_left-arrow-from-left:before {
    content: "\eb9b";
  }

  &.u_left-arrow-to-left:before {
    content: "\eb9c";
  }

  &.u_left-indent-alt:before {
    content: "\eb9d";
  }

  &.u_left-indent:before {
    content: "\eb9e";
  }

  &.u_left-to-right-text-direction:before {
    content: "\eb9f";
  }

  &.u_life-ring:before {
    content: "\eba0";
  }

  &.u_lightbulb-alt:before {
    content: "\eba1";
  }

  &.u_lightbulb:before {
    content: "\eba2";
  }

  &.u_line-alt:before {
    content: "\eba3";
  }

  &.u_line-spacing:before {
    content: "\eba4";
  }

  &.u_line:before {
    content: "\eba5";
  }

  &.u_link-add:before {
    content: "\eba6";
  }

  &.u_link-alt:before {
    content: "\eba7";
  }

  &.u_link-broken:before {
    content: "\eba8";
  }

  &.u_link-h:before {
    content: "\eba9";
  }

  &.u_link:before {
    content: "\ebaa";
  }

  &.u_linkedin-alt:before {
    content: "\ebab";
  }

  &.u_linkedin:before {
    content: "\ebac";
  }

  &.u_list-ui-alt:before {
    content: "\ebad";
  }

  &.u_list-ul:before {
    content: "\ebae";
  }

  &.u_location-arrow-alt:before {
    content: "\ebaf";
  }

  &.u_location-arrow:before {
    content: "\ebb0";
  }

  &.u_location-pin-alt:before {
    content: "\ebb1";
  }

  &.u_location-point:before {
    content: "\ebb2";
  }

  &.u_lock-access:before {
    content: "\ebb3";
  }

  &.u_lock-alt:before {
    content: "\ebb4";
  }

  &.u_lock-open-alt:before {
    content: "\ebb5";
  }

  &.u_lock-slash:before {
    content: "\ebb6";
  }

  &.u_lock:before {
    content: "\ebb7";
  }

  &.u_luggage-cart:before {
    content: "\ebb8";
  }

  &.u_mailbox-alt:before {
    content: "\ebb9";
  }

  &.u_mailbox:before {
    content: "\ebba";
  }

  &.u_map-marker-alt:before {
    content: "\ebbb";
  }

  &.u_map-marker-edit:before {
    content: "\ebbc";
  }

  &.u_map-marker-info:before {
    content: "\ebbd";
  }

  &.u_map-marker-minus:before {
    content: "\ebbe";
  }

  &.u_map-marker-plus:before {
    content: "\ebbf";
  }

  &.u_map-marker-question:before {
    content: "\ebc0";
  }

  &.u_map-marker-shield:before {
    content: "\ebc1";
  }

  &.u_map-marker-slash:before {
    content: "\ebc2";
  }

  &.u_map-marker:before {
    content: "\ebc3";
  }

  &.u_map-pin-alt:before {
    content: "\ebc4";
  }

  &.u_map-pin:before {
    content: "\ebc5";
  }

  &.u_map:before {
    content: "\ebc6";
  }

  &.u_mars:before {
    content: "\ebc7";
  }

  &.u_master-card:before {
    content: "\ebc8";
  }

  &.u_maximize-left:before {
    content: "\ebc9";
  }

  &.u_medal:before {
    content: "\ebca";
  }

  &.u_medical-drip:before {
    content: "\ebcb";
  }

  &.u_medical-square-full:before {
    content: "\ebcc";
  }

  &.u_medical-square:before {
    content: "\ebcd";
  }

  &.u_medium-m:before {
    content: "\ebce";
  }

  &.u_medkit:before {
    content: "\ebcf";
  }

  &.u_meeting-board:before {
    content: "\ebd0";
  }

  &.u_megaphone:before {
    content: "\ebd1";
  }

  &.u_meh-alt:before {
    content: "\ebd2";
  }

  &.u_meh-closed-eye:before {
    content: "\ebd3";
  }

  &.u_meh:before {
    content: "\ebd4";
  }

  &.u_message:before {
    content: "\ebd5";
  }

  &.u_metro:before {
    content: "\ebd6";
  }

  &.u_microphone-slash:before {
    content: "\ebd7";
  }

  &.u_microphone:before {
    content: "\ebd8";
  }

  &.u_minus-circle:before {
    content: "\ebd9";
  }

  &.u_minus-path:before {
    content: "\ebda";
  }

  &.u_minus-square-full:before {
    content: "\ebdb";
  }

  &.u_minus-square:before {
    content: "\ebdc";
  }

  &.u_minus:before {
    content: "\ebdd";
  }

  &.u_missed-call:before {
    content: "\ebde";
  }

  &.u_mobile-android-alt:before {
    content: "\ebdf";
  }

  &.u_mobile-android:before {
    content: "\ebe0";
  }

  &.u_mobile-vibrate:before {
    content: "\ebe1";
  }

  &.u_modem:before {
    content: "\ebe2";
  }

  &.u_money-bill-slash:before {
    content: "\ebe3";
  }

  &.u_money-bill-stack:before {
    content: "\ebe4";
  }

  &.u_money-bill:before {
    content: "\ebe5";
  }

  &.u_money-insert:before {
    content: "\ebe6";
  }

  &.u_money-stack:before {
    content: "\ebe7";
  }

  &.u_money-withdraw:before {
    content: "\ebe8";
  }

  &.u_money-withdrawal:before {
    content: "\ebe9";
  }

  &.u_moneybag-alt:before {
    content: "\ebea";
  }

  &.u_moneybag:before {
    content: "\ebeb";
  }

  &.u_monitor-heart-rate:before {
    content: "\ebec";
  }

  &.u_monitor:before {
    content: "\ebed";
  }

  &.u_moon-eclipse:before {
    content: "\ebee";
  }

  &.u_moon:before {
    content: "\ebef";
  }

  &.u_moonset:before {
    content: "\ebf0";
  }

  &.u_mountains-sun:before {
    content: "\ebf1";
  }

  &.u_mountains:before {
    content: "\ebf2";
  }

  &.u_mouse-alt-2:before {
    content: "\ebf3";
  }

  &.u_mouse-alt:before {
    content: "\ebf4";
  }

  &.u_mouse:before {
    content: "\ebf5";
  }

  &.u_multiply:before {
    content: "\ebf6";
  }

  &.u_music-note:before {
    content: "\ebf7";
  }

  &.u_music-tune-slash:before {
    content: "\ebf8";
  }

  &.u_music:before {
    content: "\ebf9";
  }

  &.u_n-a:before {
    content: "\ebfa";
  }

  &.u_navigator:before {
    content: "\ebfb";
  }

  &.u_nerd:before {
    content: "\ebfc";
  }

  &.u_newspaper:before {
    content: "\ebfd";
  }

  &.u_ninja:before {
    content: "\ebfe";
  }

  &.u_no-entry:before {
    content: "\ebff";
  }

  &.u_notebooks:before {
    content: "\ec00";
  }

  &.u_notes:before {
    content: "\ec01";
  }

  &.u_object-group:before {
    content: "\ec02";
  }

  &.u_object-ungroup:before {
    content: "\ec03";
  }

  &.u_octagon:before {
    content: "\ec04";
  }

  &.u_opera-alt:before {
    content: "\ec05";
  }

  &.u_opera:before {
    content: "\ec06";
  }

  &.u_outgoing-call:before {
    content: "\ec07";
  }

  &.u_package:before {
    content: "\ec08";
  }

  &.u_padlock:before {
    content: "\ec09";
  }

  &.u_paint-tool:before {
    content: "\ec0a";
  }

  &.u_palette:before {
    content: "\ec0b";
  }

  &.u_panel-add:before {
    content: "\ec0c";
  }

  &.u_panorama-h-alt:before {
    content: "\ec0d";
  }

  &.u_panorama-h:before {
    content: "\ec0e";
  }

  &.u_panorama-v:before {
    content: "\ec0f";
  }

  &.u_paperclip:before {
    content: "\ec10";
  }

  &.u_paragraph:before {
    content: "\ec11";
  }

  &.u_parcel:before {
    content: "\ec12";
  }

  &.u_parking-circle:before {
    content: "\ec13";
  }

  &.u_parking-square:before {
    content: "\ec14";
  }

  &.u_pathfinder-unite:before {
    content: "\ec15";
  }

  &.u_pathfinder:before {
    content: "\ec16";
  }

  &.u_pause-circle:before {
    content: "\ec17";
  }

  &.u_pause:before {
    content: "\ec18";
  }

  &.u_paypal:before {
    content: "\ec19";
  }

  &.u_pen:before {
    content: "\ec1a";
  }

  &.u_pentagon:before {
    content: "\ec1b";
  }

  &.u_percentage:before {
    content: "\ec1c";
  }

  &.u_phone-alt:before {
    content: "\ec1d";
  }

  &.u_phone-pause:before {
    content: "\ec1e";
  }

  &.u_phone-slash:before {
    content: "\ec1f";
  }

  &.u_phone-times:before {
    content: "\ec20";
  }

  &.u_phone-volume:before {
    content: "\ec21";
  }

  &.u_phone:before {
    content: "\ec22";
  }

  &.u_picture:before {
    content: "\ec23";
  }

  &.u_pizza-slice:before {
    content: "\ec24";
  }

  &.u_plane-arrival:before {
    content: "\ec25";
  }

  &.u_plane-departure:before {
    content: "\ec26";
  }

  &.u_plane-fly:before {
    content: "\ec27";
  }

  &.u_plane:before {
    content: "\ec28";
  }

  &.u_play-circle:before {
    content: "\ec29";
  }

  &.u_play:before {
    content: "\ec2a";
  }

  &.u_plug:before {
    content: "\ec2b";
  }

  &.u_plus-circle:before {
    content: "\ec2c";
  }

  &.u_plus-square:before {
    content: "\ec2d";
  }

  &.u_plus:before {
    content: "\ec2e";
  }

  &.u_podium:before {
    content: "\ec2f";
  }

  &.u_polygon:before {
    content: "\ec30";
  }

  &.u_post-stamp:before {
    content: "\ec31";
  }

  &.u_postcard:before {
    content: "\ec32";
  }

  &.u_pound-circle:before {
    content: "\ec33";
  }

  &.u_pound:before {
    content: "\ec34";
  }

  &.u_power:before {
    content: "\ec35";
  }

  &.u_prescription-bottle:before {
    content: "\ec36";
  }

  &.u_presentation-check:before {
    content: "\ec37";
  }

  &.u_presentation-edit:before {
    content: "\ec38";
  }

  &.u_presentation-line:before {
    content: "\ec39";
  }

  &.u_presentation-lines-alt:before {
    content: "\ec3a";
  }

  &.u_presentation-minus:before {
    content: "\ec3b";
  }

  &.u_presentation-play:before {
    content: "\ec3c";
  }

  &.u_presentation-plus:before {
    content: "\ec3d";
  }

  &.u_presentation-times:before {
    content: "\ec3e";
  }

  &.u_presentation:before {
    content: "\ec3f";
  }

  &.u_previous:before {
    content: "\ec40";
  }

  &.u_pricetag-alt:before {
    content: "\ec41";
  }

  &.u_print-slash:before {
    content: "\ec42";
  }

  &.u_print:before {
    content: "\ec43";
  }

  &.u_process:before {
    content: "\ec44";
  }

  &.u_processor:before {
    content: "\ec45";
  }

  &.u_pump:before {
    content: "\ec46";
  }

  &.u_puzzle-piece:before {
    content: "\ec47";
  }

  &.u_qrcode-scan:before {
    content: "\ec48";
  }

  &.u_question-circle:before {
    content: "\ec49";
  }

  &.u_rainbow:before {
    content: "\ec4a";
  }

  &.u_raindrops-alt:before {
    content: "\ec4b";
  }

  &.u_raindrops:before {
    content: "\ec4c";
  }

  &.u_react:before {
    content: "\ec4d";
  }

  &.u_receipt-alt:before {
    content: "\ec4e";
  }

  &.u_receipt:before {
    content: "\ec4f";
  }

  &.u_record-audio:before {
    content: "\ec50";
  }

  &.u_reddit-alien-alt:before {
    content: "\ec51";
  }

  &.u_redo:before {
    content: "\ec52";
  }

  &.u_refresh:before {
    content: "\ec53";
  }

  &.u_registered:before {
    content: "\ec54";
  }

  &.u_repeat:before {
    content: "\ec55";
  }

  &.u_restaurant:before {
    content: "\ec56";
  }

  &.u_right-indent-alt:before {
    content: "\ec57";
  }

  &.u_right-to-left-text-direction:before {
    content: "\ec58";
  }

  &.u_robot:before {
    content: "\ec59";
  }

  &.u_rocket:before {
    content: "\ec5a";
  }

  &.u_rope-way:before {
    content: "\ec5b";
  }

  &.u_rotate-360:before {
    content: "\ec5c";
  }

  &.u_rss-alt:before {
    content: "\ec5d";
  }

  &.u_rss-interface:before {
    content: "\ec5e";
  }

  &.u_rss:before {
    content: "\ec5f";
  }

  &.u_ruler-combined:before {
    content: "\ec60";
  }

  &.u_ruler:before {
    content: "\ec61";
  }

  &.u_rupee-sign:before {
    content: "\ec62";
  }

  &.u_sad-cry:before {
    content: "\ec63";
  }

  &.u_sad-crying:before {
    content: "\ec64";
  }

  &.u_sad-dizzy:before {
    content: "\ec65";
  }

  &.u_sad-squint:before {
    content: "\ec66";
  }

  &.u_sad:before {
    content: "\ec67";
  }

  &.u_save:before {
    content: "\ec68";
  }

  &.u_scaling-left:before {
    content: "\ec69";
  }

  &.u_scaling-right:before {
    content: "\ec6a";
  }

  &.u_scenery:before {
    content: "\ec6b";
  }

  &.u_schedule:before {
    content: "\ec6c";
  }

  &.u_screw:before {
    content: "\ec6d";
  }

  &.u_scroll-h:before {
    content: "\ec6e";
  }

  &.u_scroll:before {
    content: "\ec6f";
  }

  &.u_search-alt:before {
    content: "\ec70";
  }

  &.u_search-minus:before {
    content: "\ec71";
  }

  &.u_search-plus:before {
    content: "\ec72";
  }

  &.u_search:before {
    content: "\ec73";
  }

  &.u_selfie:before {
    content: "\ec74";
  }

  &.u_server-alt:before {
    content: "\ec75";
  }

  &.u_server-connection:before {
    content: "\ec76";
  }

  &.u_server-network-alt:before {
    content: "\ec77";
  }

  &.u_server-network:before {
    content: "\ec78";
  }

  &.u_server:before {
    content: "\ec79";
  }

  &.u_servers:before {
    content: "\ec7a";
  }

  &.u_servicemark:before {
    content: "\ec7b";
  }

  &.u_setting:before {
    content: "\ec7c";
  }

  &.u_share-alt:before {
    content: "\ec7d";
  }

  &.u_shield-check:before {
    content: "\ec7e";
  }

  &.u_shield-exclamation:before {
    content: "\ec7f";
  }

  &.u_shield-question:before {
    content: "\ec80";
  }

  &.u_shield-slash:before {
    content: "\ec81";
  }

  &.u_shield:before {
    content: "\ec82";
  }

  &.u_ship:before {
    content: "\ec83";
  }

  &.u_shop:before {
    content: "\ec84";
  }

  &.u_shopping-bag:before {
    content: "\ec85";
  }

  &.u_shopping-basket:before {
    content: "\ec86";
  }

  &.u_shopping-cart-alt:before {
    content: "\ec87";
  }

  &.u_shopping-cart:before {
    content: "\ec88";
  }

  &.u_shovel:before {
    content: "\ec89";
  }

  &.u_shrink:before {
    content: "\ec8a";
  }

  &.u_shuffle:before {
    content: "\ec8b";
  }

  &.u_shutter-alt:before {
    content: "\ec8c";
  }

  &.u_shutter:before {
    content: "\ec8d";
  }

  &.u_sick:before {
    content: "\ec8e";
  }

  &.u_sigma:before {
    content: "\ec8f";
  }

  &.u_sign-alt:before {
    content: "\ec90";
  }

  &.u_sign-in-alt:before {
    content: "\ec91";
  }

  &.u_sign-left:before {
    content: "\ec92";
  }

  &.u_sign-out-alt:before {
    content: "\ec93";
  }

  &.u_sign-right:before {
    content: "\ec94";
  }

  &.u_signal-alt-3:before {
    content: "\ec95";
  }

  &.u_signal-alt:before {
    content: "\ec96";
  }

  &.u_signal:before {
    content: "\ec97";
  }

  &.u_signin:before {
    content: "\ec98";
  }

  &.u_silence:before {
    content: "\ec99";
  }

  &.u_silent-squint:before {
    content: "\ec9a";
  }

  &.u_sim-card:before {
    content: "\ec9b";
  }

  &.u_sitemap:before {
    content: "\ec9c";
  }

  &.u_skip-forward-alt:before {
    content: "\ec9d";
  }

  &.u_skip-forward-circle:before {
    content: "\ec9e";
  }

  &.u_skip-forward:before {
    content: "\ec9f";
  }

  &.u_skype-alt:before {
    content: "\eca0";
  }

  &.u_skype:before {
    content: "\eca1";
  }

  &.u_slack-alt:before {
    content: "\eca2";
  }

  &.u_slack:before {
    content: "\eca3";
  }

  &.u_sliders-v-alt:before {
    content: "\eca4";
  }

  &.u_sliders-v:before {
    content: "\eca5";
  }

  &.u_smile-beam:before {
    content: "\eca6";
  }

  &.u_smile-dizzy:before {
    content: "\eca7";
  }

  &.u_smile-squint-wink-alt:before {
    content: "\eca8";
  }

  &.u_smile-squint-wink:before {
    content: "\eca9";
  }

  &.u_smile-wink-alt:before {
    content: "\ecaa";
  }

  &.u_smile-wink:before {
    content: "\ecab";
  }

  &.u_smile:before {
    content: "\ecac";
  }

  &.u_snapchat-alt:before {
    content: "\ecad";
  }

  &.u_snapchat-ghost:before {
    content: "\ecae";
  }

  &.u_snapchat-square:before {
    content: "\ecaf";
  }

  &.u_snow-flake:before {
    content: "\ecb0";
  }

  &.u_snowflake-alt:before {
    content: "\ecb1";
  }

  &.u_snowflake:before {
    content: "\ecb2";
  }

  &.u_sort-amount-down:before {
    content: "\ecb3";
  }

  &.u_sort-amount-up:before {
    content: "\ecb4";
  }

  &.u_sort:before {
    content: "\ecb5";
  }

  &.u_sorting:before {
    content: "\ecb6";
  }

  &.u_space-key:before {
    content: "\ecb7";
  }

  &.u_spade:before {
    content: "\ecb8";
  }

  &.u_sperms:before {
    content: "\ecb9";
  }

  &.u_spin:before {
    content: "\ecba";
  }

  &.u_square-full:before {
    content: "\ecbb";
  }

  &.u_square-shape:before {
    content: "\ecbc";
  }

  &.u_square:before {
    content: "\ecbd";
  }

  &.u_squint:before {
    content: "\ecbe";
  }

  &.u_star-half-alt:before {
    content: "\ecbf";
  }

  &.u_star:before {
    content: "\ecc0";
  }

  &.u_step-backward-alt:before {
    content: "\ecc1";
  }

  &.u_step-backward-circle:before {
    content: "\ecc2";
  }

  &.u_step-backward:before {
    content: "\ecc3";
  }

  &.u_step-forward:before {
    content: "\ecc4";
  }

  &.u_stop-circle:before {
    content: "\ecc5";
  }

  &.u_stopwatch-slash:before {
    content: "\ecc6";
  }

  &.u_stopwatch:before {
    content: "\ecc7";
  }

  &.u_store-alt:before {
    content: "\ecc8";
  }

  &.u_store:before {
    content: "\ecc9";
  }

  &.u_streering:before {
    content: "\ecca";
  }

  &.u_stretcher:before {
    content: "\eccb";
  }

  &.u_subject:before {
    content: "\eccc";
  }

  &.u_subway-alt:before {
    content: "\eccd";
  }

  &.u_subway:before {
    content: "\ecce";
  }

  &.u_suitcase-alt:before {
    content: "\eccf";
  }

  &.u_suitcase:before {
    content: "\ecd0";
  }

  &.u_sun:before {
    content: "\ecd1";
  }

  &.u_sunset:before {
    content: "\ecd2";
  }

  &.u_surprise:before {
    content: "\ecd3";
  }

  &.u_swatchbook:before {
    content: "\ecd4";
  }

  &.u_swiggy:before {
    content: "\ecd5";
  }

  &.u_swimmer:before {
    content: "\ecd6";
  }

  &.u_sync-exclamation:before {
    content: "\ecd7";
  }

  &.u_sync-slash:before {
    content: "\ecd8";
  }

  &.u_sync:before {
    content: "\ecd9";
  }

  &.u_syringe:before {
    content: "\ecda";
  }

  &.u_table-tennis:before {
    content: "\ecdb";
  }

  &.u_table:before {
    content: "\ecdc";
  }

  &.u_tablet:before {
    content: "\ecdd";
  }

  &.u_tablets:before {
    content: "\ecde";
  }

  &.u_tachometer-fast-alt:before {
    content: "\ecdf";
  }

  &.u_tachometer-fast:before {
    content: "\ece0";
  }

  &.u_tag-alt:before {
    content: "\ece1";
  }

  &.u_tag:before {
    content: "\ece2";
  }

  &.u_tape:before {
    content: "\ece3";
  }

  &.u_taxi:before {
    content: "\ece4";
  }

  &.u_tear:before {
    content: "\ece5";
  }

  &.u_telegram-alt:before {
    content: "\ece6";
  }

  &.u_telegram:before {
    content: "\ece7";
  }

  &.u_telescope:before {
    content: "\ece8";
  }

  &.u_temperature-empty:before {
    content: "\ece9";
  }

  &.u_temperature-half:before {
    content: "\ecea";
  }

  &.u_temperature-minus:before {
    content: "\eceb";
  }

  &.u_temperature-plus:before {
    content: "\ecec";
  }

  &.u_temperature-quarter:before {
    content: "\eced";
  }

  &.u_temperature-three-quarter:before {
    content: "\ecee";
  }

  &.u_temperature:before {
    content: "\ecef";
  }

  &.u_tennis-ball:before {
    content: "\ecf0";
  }

  &.u_text-fields:before {
    content: "\ecf1";
  }

  &.u_text-size:before {
    content: "\ecf2";
  }

  &.u_text-strike-through:before {
    content: "\ecf3";
  }

  &.u_text:before {
    content: "\ecf4";
  }

  &.u_th-large:before {
    content: "\ecf5";
  }

  &.u_th-slash:before {
    content: "\ecf6";
  }

  &.u_th:before {
    content: "\ecf7";
  }

  &.u_thermometer:before {
    content: "\ecf8";
  }

  &.u_thumbs-down:before {
    content: "\ecf9";
  }

  &.u_thumbs-up:before {
    content: "\ecfa";
  }

  &.u_thunderstorm-moon:before {
    content: "\ecfb";
  }

  &.u_thunderstorm-sun:before {
    content: "\ecfc";
  }

  &.u_thunderstorm:before {
    content: "\ecfd";
  }

  &.u_ticket:before {
    content: "\ecfe";
  }

  &.u_times-circle:before {
    content: "\ecff";
  }

  &.u_times-square:before {
    content: "\ed00";
  }

  &.u_times:before {
    content: "\ed01";
  }

  &.u_toggle-off:before {
    content: "\ed02";
  }

  &.u_toggle-on:before {
    content: "\ed03";
  }

  &.u_top-arrow-from-top:before {
    content: "\ed04";
  }

  &.u_top-arrow-to-top:before {
    content: "\ed05";
  }

  &.u_tornado:before {
    content: "\ed06";
  }

  &.u_trademark-circle:before {
    content: "\ed07";
  }

  &.u_trademark:before {
    content: "\ed08";
  }

  &.u_traffic-barrier:before {
    content: "\ed09";
  }

  &.u_traffic-light:before {
    content: "\ed0a";
  }

  &.u_transaction:before {
    content: "\ed0b";
  }

  &.u_trash-alt:before {
    content: "\ed0c";
  }

  &.u_trash:before {
    content: "\ed0d";
  }

  &.u_trees:before {
    content: "\ed0e";
  }

  &.u_triangle:before {
    content: "\ed0f";
  }

  &.u_trophy:before {
    content: "\ed10";
  }

  &.u_trowel:before {
    content: "\ed11";
  }

  &.u_truck-loading:before {
    content: "\ed12";
  }

  &.u_truck:before {
    content: "\ed13";
  }

  &.u_tumblr-alt:before {
    content: "\ed14";
  }

  &.u_tumblr-square:before {
    content: "\ed15";
  }

  &.u_tumblr:before {
    content: "\ed16";
  }

  &.u_tv-retro-slash:before {
    content: "\ed17";
  }

  &.u_tv-retro:before {
    content: "\ed18";
  }

  &.u_twitter-alt:before {
    content: "\ed19";
  }

  &.u_twitter:before {
    content: "\ed1a";
  }

  &.u_umbrella:before {
    content: "\ed1b";
  }

  &.u_unamused:before {
    content: "\ed1c";
  }

  &.u_underline:before {
    content: "\ed1d";
  }

  &.u_university:before {
    content: "\ed1e";
  }

  &.u_unlock-alt:before {
    content: "\ed1f";
  }

  &.u_unlock:before {
    content: "\ed20";
  }

  &.u_upload-alt:before {
    content: "\ed21";
  }

  &.u_upload:before {
    content: "\ed22";
  }

  &.u_usd-circle:before {
    content: "\ed23";
  }

  &.u_usd-square:before {
    content: "\ed24";
  }

  &.u_user-check:before {
    content: "\ed25";
  }

  &.u_user-circle:before {
    content: "\ed26";
  }

  &.u_user-exclamation:before {
    content: "\ed27";
  }

  &.u_user-location:before {
    content: "\ed28";
  }

  &.u_user-minus:before {
    content: "\ed29";
  }

  &.u_user-plus:before {
    content: "\ed2a";
  }

  &.u_user-square:before {
    content: "\ed2b";
  }

  &.u_user-times:before {
    content: "\ed2c";
  }

  &.u_user:before {
    content: "\ed2d";
  }

  &.u_users-alt:before {
    content: "\ed2e";
  }

  &.u_utensils-alt:before {
    content: "\ed2f";
  }

  &.u_utensils:before {
    content: "\ed30";
  }

  &.u_vector-square-alt:before {
    content: "\ed31";
  }

  &.u_vector-square:before {
    content: "\ed32";
  }

  &.u_venus:before {
    content: "\ed33";
  }

  &.u_vertical-align-bottom:before {
    content: "\ed34";
  }

  &.u_vertical-align-center:before {
    content: "\ed35";
  }

  &.u_vertical-align-top:before {
    content: "\ed36";
  }

  &.u_vertical-distribute-bottom:before {
    content: "\ed37";
  }

  &.u_vertical-distribution-center:before {
    content: "\ed38";
  }

  &.u_vertical-distribution-top:before {
    content: "\ed39";
  }

  &.u_video-slash:before {
    content: "\ed3a";
  }

  &.u_video:before {
    content: "\ed3b";
  }

  &.u_visual-studio:before {
    content: "\ed3c";
  }

  &.u_vk-alt:before {
    content: "\ed3d";
  }

  &.u_vk:before {
    content: "\ed3e";
  }

  &.u_voicemail-rectangle:before {
    content: "\ed3f";
  }

  &.u_voicemail:before {
    content: "\ed40";
  }

  &.u_volleyball:before {
    content: "\ed41";
  }

  &.u_volume-down:before {
    content: "\ed42";
  }

  &.u_volume-mute:before {
    content: "\ed43";
  }

  &.u_volume-off:before {
    content: "\ed44";
  }

  &.u_volume-up:before {
    content: "\ed45";
  }

  &.u_volume:before {
    content: "\ed46";
  }

  &.u_vuejs-alt:before {
    content: "\ed47";
  }

  &.u_vuejs:before {
    content: "\ed48";
  }

  &.u_wall:before {
    content: "\ed49";
  }

  &.u_wallet:before {
    content: "\ed4a";
  }

  &.u_watch-alt:before {
    content: "\ed4b";
  }

  &.u_watch:before {
    content: "\ed4c";
  }

  &.u_water-drop-slash:before {
    content: "\ed4d";
  }

  &.u_water-glass:before {
    content: "\ed4e";
  }

  &.u_water:before {
    content: "\ed4f";
  }

  &.u_web-grid-alt:before {
    content: "\ed50";
  }

  &.u_web-grid:before {
    content: "\ed51";
  }

  &.u_web-section-alt:before {
    content: "\ed52";
  }

  &.u_web-section:before {
    content: "\ed53";
  }

  &.u_webcam:before {
    content: "\ed54";
  }

  &.u_weight:before {
    content: "\ed55";
  }

  &.u_whatsapp-alt:before {
    content: "\ed56";
  }

  &.u_whatsapp:before {
    content: "\ed57";
  }

  &.u_wheel-barrow:before {
    content: "\ed58";
  }

  &.u_wheelchair-alt:before {
    content: "\ed59";
  }

  &.u_wheelchair:before {
    content: "\ed5a";
  }

  &.u_wifi-router:before {
    content: "\ed5b";
  }

  &.u_wifi-slash:before {
    content: "\ed5c";
  }

  &.u_wifi:before {
    content: "\ed5d";
  }

  &.u_wind-moon:before {
    content: "\ed5e";
  }

  &.u_wind-sun:before {
    content: "\ed5f";
  }

  &.u_wind:before {
    content: "\ed60";
  }

  &.u_window-grid:before {
    content: "\ed61";
  }

  &.u_window-maximize:before {
    content: "\ed62";
  }

  &.u_window-section:before {
    content: "\ed63";
  }

  &.u_window:before {
    content: "\ed64";
  }

  &.u_windsock:before {
    content: "\ed65";
  }

  &.u_windy:before {
    content: "\ed66";
  }

  &.u_wrap-text:before {
    content: "\ed67";
  }

  &.u_wrench:before {
    content: "\ed68";
  }

  &.u_x-add:before {
    content: "\ed69";
  }

  &.u_x:before {
    content: "\ed6a";
  }

  &.u_yen-circle:before {
    content: "\ed6b";
  }

  &.u_yen:before {
    content: "\ed6c";
  }

  &.u_yin-yang:before {
    content: "\ed6d";
  }

  &.u_youtube:before {
    content: "\ed6e";
  }
}



