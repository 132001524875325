@use 'assets/utils' as *;

.modal {
  display: flex;
  width: max-content;
  height: max-content;
  justify-content: center;
  align-items: center;
  @include fixed(50%, 0, 0, 50%);
  z-index: 10000000;
  background: $dark-gunmetal;
  flex-direction: column;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;

  @include screen('phablet', 'max') {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0;
  }

  &-backdrop {
    @include fixed(0, 0, 0, 0);
    background: rgba(14, 17, 33, 0.9);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: $yankee-blue;
    width: 100%;

    h1 {
      @include setFont(18px, 600, normal, $bright-gray);
      margin: 0;
    }

    i {
      cursor: pointer;
      color: $bright-gray;
      font-size: 20px;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 32px 28px;

    button {
      width: auto;

      @include screen('phablet', 'max') {
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 40px 24px;
    overflow-y: auto;
    flex-grow: 1;

    &:has(iframe) {
      padding: 0;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}