@for $i from 1 to 41 {
    $count: $i+1;
    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }
    .mt-#{$i} {
        margin-top: #{$i}px;
    }
    .ml-#{$i} {
        margin-left: #{$i}px;
    }
    .mr-#{$i} {
        margin-right: #{$i}px;
    }
}

@for $i from 1 to 41 {
    $count: $i+1;
    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }
    .pt-#{$i} {
        padding-top: #{$i}px;
    }
    .pl-#{$i} {
        padding-left: #{$i}px;
    }
    .pr-#{$i} {
        padding-right: #{$i}px;
    }
}
