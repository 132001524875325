@use 'assets/utils' as *;

$content-padding: 24px;
$container-width: map-get($breakpoints, "large-desktop") + ($side-menu-width * 2) + ($content-padding * 2);

html {
  background: $black;

  body {
    max-width: $container-width;
    margin: 0 auto;
  }

  .app-container {
    min-height: 100vh;
    position: relative;

    .top-shadow {
      @include fixed(0, 0, 0, 0);
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: -10%;
        left: 30%;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #7738FB;
        box-shadow: 0 0 20rem 10rem #7738FB;
        filter: blur(5rem);
      }

      &:after {
        content: '';
        position: absolute;
        top: -8%;
        left: 50%;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FBF338;
        box-shadow: 0 0 10rem 5rem #FBF338;
        filter: blur(5rem);
      }
    }

    .bottom-shadow {
      @include fixed(0, 0, 0, 0);
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        bottom: -20%;
        right: 15%;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #7738FB;
        box-shadow: 0 0 20rem 20rem #7738FB;
        filter: blur(9rem);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 15%;
        right: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #FBF338;
        box-shadow: 0 0 20rem 5rem #FBF338;
        filter: blur(7rem);
      }
    }

    @include screen('phablet', 'max') {
      .top-shadow,
      .bottom-shadow {
        display: none;
      }
    }

    @include screen('phablet', 'min') {
      app-root {
        position: relative;
      }
    }

    
    app-root {
      z-index: 2;
    }
  }

  .container-with-sidebar {
    min-height: 100vh;
    display: flex;
    position: relative;
    z-index: 2;
    margin: 0 $content-padding;
    gap: $content-padding;

    .container-body {
      flex-grow: 1;
      overflow-x: auto;
      position: relative;
      max-width: map-get($breakpoints, "large-desktop");
      margin: $content-padding auto;
    }

    &:has(.container-sidebar) {
      &:after {
        content: '';
        min-width: $side-menu-width;
      }

      &:has(.navigation-bar.minimize) {
        &:after {
          min-width: $side-menu-width-minimize;
        }
      }
    }

    &:not(:has(.container-sidebar)) {
      max-width: map-get($breakpoints, "large-desktop");
      margin: 0 auto;
    }

    @media only screen and (max-width: 1920px) {
      &:after {
        display: none;
      }
    }

    @include screen('laptop', 'max') {
      padding: 0 0 10px 0;

      .container-sidebar,
      &:after {
        display: none;
      }
    }

    @include screen('phablet', 'max') {
      margin: 0;

      .container-body {
        margin: 0;
      }
    }
  }

  &:has(.page-loader.active) {
    overflow: hidden;
  }

  &:has(.cdk-global-overlay-wrapper) {
    .app-container {
      overflow: hidden !important;
    }
  }
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #191d32;
  z-index: 100000000;
  align-items: center;
  justify-content: center;
  display: none;

  &.active {
    display: flex;
  }

  &__image {
    position: relative;
    @include square(80px);

    &:before {
      background-image: url("../images/loading.svg");
      content: "";
      position: absolute;
      top: -90px;
      left: -90px;
      bottom: -90px;
      right: -90px;
      opacity: 0.1;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.main-layout {
  width: 100%;
  display: flex;
}

::ng-deep .snackbar-box {
  .mdc-snackbar__surface {
    background-color: #1A1841 !important;

    simple-snack-bar {
      .mdc-snackbar__label {
        color: #FBF338 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        white-space: break-spaces;
        font-size: 18px;
        line-height: 24px;
        column-gap: 20px;

        &:before {
          display: none;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  width: 100%;
}

@include screen('laptop', 'max') {
  .main-layout {
    padding: $content-padding;
  }
}

@include screen('phablet', 'max') {
  .main-layout {
    padding: 0;
  }

  .container {
    border-radius: unset;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cdk-overlay-container {
  z-index: 1000 !important;
}

.toast-container {
  margin-top: 8px;

  .ngx-toastr {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  }
}

#pnm_container {
  z-index: 100000 !important;
  width: 0 !important;
  height: 0 !important;

  #pnm_modal {
    height: min(100vh, 1070px) !important;
    width: min(100vw, 1070px) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 50px;
    background: linear-gradient(55deg, #191d31 52%, #34206e 129%);

    @include screen('phablet', 'max') {
      border-radius: 0 !important;
    }

    &:before {
      content: "\ecff";
      font-family: unicons !important;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 15px;
      z-index: 100;
      color: $white;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      background: #7036ea;
      height: 50px;
      width: 100%;
      top: 0;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      @include screen('phablet', 'max') {
        border-radius: 0 !important;
      }
    }
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(14,17,33,.9) !important;
    z-index: 99999;
  }
}

.loader-component {
  position: relative;
  color: transparent;

  &:after {
    content: '';
    @include absolute(0,0,0,0);
    background-image: url("../images/loading.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  > * {
    visibility: hidden;
  }
}

.show-modal-with-mobile-navigation {
  position: fixed!important;
  bottom: 59px; 
  left: 0;
  right: 0;
  overflow-y: auto;
  width: 100%!important;
  border-radius: 0!important;
  z-index: 1000000;
  background-color: #191D32;
}

.display-modal-with-mobile-navigation-authorize {
  @extend .show-modal-with-mobile-navigation;
  top: 73px!important;
  height: calc(100dvh - 132px)!important;
}

.display-modal-with-mobile-navigation-unauthorize {
  @extend .show-modal-with-mobile-navigation;
  top: 54px!important; 
  height: calc(100dvh - 113px)!important;
}

.footer-message {
  text-align: center;
  font-size: 14px;
  margin-top: 35px;
  padding-bottom: 35px;

  span {
    color: $white;
  }

  a {
    color: $blue-violet;
    cursor: pointer;
  }
}

.support-link {
  color: #FBF338;
}
