.rotate-90 {
  transform: rotate(90deg);
}

.text-right {
  text-align: right;
}

.new-section-heading {
  margin-top: 60px;
}