@use 'assets/utils' as *;

$color: $purple-dawn;
$accent-color: $purple-days;

.skeleton-loading:empty {
  position: relative;
  background: $color;
  overflow: hidden;

  &.animating {
    &:before {
      content: '';
      @include absolute(0,0,0,0);
      animation: loading 1.1s infinite linear;

      background-repeat: no-repeat;
      background-image: linear-gradient(to right, transparent 0%, $accent-color 20%, transparent 40%, transparent 100%);
    }
  }

  @for $i from 1 to 200 {
    $count: $i+1;
    &.size-#{$i} {
      height: #{$i}px;
    }
  }

  &.half {
    width: 50%;
  }

  & ~ .skeleton-loading:empty {
    margin-top: 5px;
  }
}

@keyframes loading {
  0% {
    left: 110%;
    right: -110%;
  }
  100% {
    left: -10%;
    right: -10%;
  }
}