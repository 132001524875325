@use 'assets/utils' as *;

.tooltip-container {
    z-index: 100;
    position: fixed;
    padding: 4px 12px;
    max-width: 330px;
    font-size: 14px;
    line-height: initial;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translate(-50%, -30%);
    animation: tooltip-slide 0.18s ease-out 0.1s;
    animation-fill-mode: forwards;
    pointer-events: none;
    h1 {
      font-size: 16px;
      @include setFont(16px, normal, normal, $black);
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      display: block;
      @include setFont(14px, normal, normal, $black);
    }
}


.center {
  text-align: center;
}

.black {
  h1, span {
    color: $white;
  }
  background: $black;
}

.white {
  h1, span {
    color: $black;
  }
  background: $white;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}