@use 'assets/utils' as *;

.form-control {
  display: flex;
  flex-direction: column;

  &__label {
    @include setFont(14px, normal, normal, $bright-gray);
    margin-bottom: 8px;
  }

  .select {
    .ng-select-container {
      border: 1px solid $dark-gunmetal;
      background-color: $yankee-blue;
      border-radius: 8px;
      width: 100%;
      height: 50px !important;
      padding: 15px !important;

      .ng-value-container {
        padding-left: 0 !important;

        .ng-value {
          color: $bright-gray;
        }

        .ng-input {
          top: 13px !important;

          input {
            color: $bright-gray;
            padding-left: 5px !important;
          }
        }
      }
    }

    .ng-dropdown-panel {
      border: none !important;
      background: none !important;

      .scroll-host {
        background-color: $yankee-blue;
        border-radius: 5px;

        .ng-option {
          background: none !important;
          color: $bright-gray;
          padding: 22px;

          &.ng-option-marked {
            background-color: $dark-gunmetal !important;
          }
        }
      }
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;
    border: 1px solid $dark-gunmetal;
    background-color: $yankee-blue;

    &:focus-within {
      border-color: $american-blue;

      i, input {
        color: $bright-gray;
      }
    }

    &:hover {
      border-color: $american-blue;

      i, input, input::placeholder {
        color: $bright-gray;
      }
    }

    &:has(input:not(:placeholder-shown)) {
      border-color: $american-blue;
      background-color: $dark-gunmetal;

      i, input, input::placeholder {
        color: $bright-gray;
      }
    }

    i {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      color: $american-blue;

      &.icon-before {
        left: 19px;
      }

      &.icon-after {
        left: auto;
        right: 19px;
      }
    }

    input {
      outline: none !important;
      padding: 0 15px;
      background: transparent;
      min-height: 50px;
      min-width: 120px;
      width: 100%;
      border: none;
      position: relative;
      @include setFont(16px, normal, 16px, $bright-gray);

      &::placeholder {
        color: $american-blue;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    }

    i + input {
      padding-left: 56px;
    }

    &.invalid {
      border-color: $coral-red !important;

      &:not(:has(.icon-after)) {
        &:after {
          font-family: 'unicons';
          content: "\eac7";
          color: $coral-red !important;
          font-size: 24px;
          position: absolute;
          top: 50%;
          right: 19px;
          transform: translateY(-50%);
        }
      }

      i, input, input::placeholder {
        color: $coral-red !important;
      }
    }

    &.valid {
      border-color: $guppie-green !important;

      &:not(:has(.icon-after)) {
        &:after {
          font-family: 'unicons';
          content: "\e9db";
          color: $guppie-green !important;
          font-size: 24px;
          position: absolute;
          top: 50%;
          right: 19px;
          transform: translateY(-50%);
        }
      }

      i, input, input::placeholder {
        color: $guppie-green !important;
      }
    }
  }
}

.form-control-check {
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;

    .checkbox {
      margin-right: 15px;

      input {
        width: 20px;
        height: 20px;
      }
    }

    span {
      display: block;
      @include setFont(14px, 500, 20px, $black);
    }
  }
}

.form-control-radio {
  &__block {
    margin-bottom: 12px;

    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.form-control-readonly {
  &__transparent {
    .form-control__block {
      background-color: transparent !important;
    }
  }

  label {
    color: $grey !important;
  }

  .form-control__block {
    .edit {
      font-size: 23px;
      cursor: pointer;
    }

    border: none !important;

    input {
      padding: 5px 0 !important;
      cursor: default;
      user-select: none;
    }
  }
}

.form-control__col-3 {
  width: 33%;
}

.form-control__col-2 {
  width: 50%;
}

.form-list {
  &__item {
    @include setFont(16px, bold, normal, $black);
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.error-message {
  display: block;
  @include setFont(14px, normal, normal, $coral-red !important);

  &:before {
    font-family: 'unicons';
    content: "\e9db";
  }
}

.success-message {
  display: block;
  color: $guppie-green;
  @include setFont(14px, normal, normal, $guppie-green !important);

  &:before {
    font-family: 'unicons';
    content: "\e9db";
  }
}

// Additional form style

.form-title {
  @include setFont(36px, 300, normal, $black);
}

.form-title-grey {
  @include setFont(21px, normal, normal, $grey);
}

//.question-center {
//  text-align: center;
//}

//.question {
//  @include setFont(16px, normal, 20px, $black);
//  display: block;
//
//  a {
//    color: $primary;
//  }
//}

// Toggle input

.switch-control {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 21px;

    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5px;
      background-color: $white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translate(18.5px, -50%);
    -ms-transform: translate(18.5px, -50%);
    transform: translate(18.5px, -50%);
  }
}

//.form-note {
//  @include setFont(16px, normal, normal, $grey);
//  display: block;
//}
//
//.form-note-bold {
//  @include setFont(16px, bold, normal, $grey);
//  display: block;
//}

.form-checkbox {
  color: $lilac-sky;
  display: flex;
  gap: 18px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 18px;

  &:before {
    content: "\ecbb";
    font-size: 20px;
    font-family: 'unicons' !important;
  }

  &:has(input:checked) {
    &:before {
      content: "\e9dc";
    }
  }

  &.error {
    color: $coral-red;
  }

  span {
    font-size: 10px;
    line-height: 12px;
  }

  input {
    display: none;
  }
}

.responsive-table {
  @include screen('laptop', 'max') {
    table {
      thead {
        display: none;
      }

      tbody {
        display: flex;
        flex-wrap: wrap;
        padding: 4px;

        tr,
        td {
          display: block;
          border: 0;
        }

        tr {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          width: 100%;
          height: auto !important;
          padding: 24px;
          margin-bottom: 16px;
          border-radius: 24px;
        }

        td {
          padding: 4px;
          font-weight: bold;
          border: none !important;

          &:before {
            color: $bright-gray;
            content: attr(data-name);
            display: inline-block;
            font-weight: bold;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}