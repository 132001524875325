@use 'assets/utils' as *;

$game-gap-size: 12px;
$edge-size: 48px;
$edge-margin-size: calc($edge-size / 2);

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $black;
  border-radius: 16px;
  padding: 6px 12px;

  &.centered {
    justify-content: center;
  }

  &.big {
    padding: 22px;
    border-radius: 24px;
  }

  &.blue {
    background-color: $yankee-blue;
  }

  &.lightColorScroll {
    ::-webkit-scrollbar-thumb {
      background-color: $american-blue;
    }
  }

  @include screen('phablet', 'max') {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
    background-color: $black !important;
    padding: 6px 12px !important;
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    margin-right: 30px;
    overflow-x: auto;

    @include screen('phablet', 'max') {
      margin-right: 0;
    }

    @include screen('mobile', 'max') {
      overflow-y: hidden;
      justify-content: flex-start;
      padding-left: 24px;
      padding-right: 24px;
    }

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 12px 30px;

      @include screen('phablet', 'max') {
        padding: 12px 16px;
      }

      &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        outline: none;
        box-shadow: none;
        border: none;
        font-size: 16px;
        font-weight: 500;
        z-index: 0;
        color: $bright-gray;
        position: relative;
        background-image: $tertiary-gradient;

        &::before {
          content: '';
          position: absolute;
          top: 1px;
          right: 1px;
          bottom: 1px;
          left: 1px;
          background-color: $chinese-black;
          border-radius: 10px;
          box-sizing: border-box;
          z-index: -1;
        }
      }

      span {
        white-space: nowrap;
        color: $bright-gray;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 8px;

    @include screen('phablet', 'max') {
      display: none;
    }
  }
}

.games-block {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 40px $edge-margin-size 0;
    min-height: 36px;
    align-items: center;

    @include screen('phablet', 'max') {
      margin: 0 $edge-margin-size 0;
    }

    &__left {
      display: flex;

      .image-icon {
        width: 16px;
        margin-right: 12px;
      }

      .title {
        @include setFont(16px, 500, normal, $bright-gray);
        text-transform: capitalize;
      }
    }

    &__right {
      @include setFont(14px, 600, normal, $primary);
      display: flex;
      gap: 12px;
      cursor: pointer;

      .show {
        white-space: nowrap;
      }

      .arrow-left,
      .arrow-right {
        padding: 8px 10px;
      }

      @include screen('laptop', 'max') {
        .arrow-left,
        .arrow-right {
          display: none;
        }
      }
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 16px $edge-margin-size;

    @include screen('tablet', 'min') {
      grid-template-columns: repeat(4, 1fr)
    }

    @include screen('laptop', 'min') {
      grid-template-columns: repeat(5, 1fr)
    }

    @include screen('desktop', 'min') {
      grid-template-columns: repeat(6, 1fr)
    }

    .game-item {
      margin: 0 calc($game-gap-size / 2);

      &.placeholder {
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .game-image-placeholder {
          width: 100%;
          aspect-ratio: 1/1;
          margin: 0;
          border-radius: 16px;
        }

        .game-title-placeholder {
          margin-top: 8px;
          height: 16px;
          width: 70%;
          margin-bottom: 17px;
        }
      }
    }

    &.loading {
      .game-item {
        display: initial;

        &.placeholder {
          display: flex;
        }
      }

      &.vertical {
        .game-item {
          display: initial;
        }
      }
    }

    &:not(.vertical) {
      padding-bottom: 0;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      gap: $game-gap-size;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }

      .game-item {
        margin: 0;
        min-width: calc((100% - ($game-gap-size * 2)) / 3);
      }

      @include screen('tablet', 'min') {
        .game-item {
          min-width: calc((100% - ($game-gap-size * 3)) / 4);
        }
      }

      @include screen('laptop', 'min') {
        .game-item {
          min-width: calc((100% - ($edge-margin-size * 2) - ($game-gap-size * 4)) / 5);
        }
      }

      @include screen('desktop', 'min') {
        .game-item {
          min-width: calc((100% - ($edge-margin-size * 2) - ($game-gap-size * 5)) / 6);
        }
      }
    }
  }
}