@use '@angular/material' as mat;
@import './assets/utils';
@import './assets/libs';
@import './assets/scss/tooltip';
@import './assets/scss/layout';
@import './assets/scss/icons';
@import './assets/scss/buttons';
@import './assets/scss/form';
@import './assets/scss/material-form';
@import './assets/scss/modal.scss';
@import './assets/scss/typography';
@import './assets/scss/skeleton-loading';
@import './assets/scss/margin&padding';
@import './assets/scss/components';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@include mat.core();