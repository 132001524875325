@use 'assets/utils' as *;

$input-radius: 10px;

.material-block {
  width: 100%;

  mat-label,
  .mat-mdc-form-field-required-marker {
    color: $bright-gray;
  }

  &.valid,
  &.ng-valid.ng-touched {
    mat-label,
    .mat-mdc-form-field-required-marker {
      color: $guppie-green;
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch {
      border-color: $guppie-green !important;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            @include setFont(14px, 400, 17px, $guppie-green);

            &::placeholder {
              color: $guppie-green;
            }
          }
        }
      }
    }

    .mat-button-wrapper {
      color: $guppie-green !important;
    }

    mat-icon {
      color: $guppie-green !important;
    }
  }

  &.invalid,
  &.ng-invalid.ng-touched {
    mat-label,
    .mat-mdc-form-field-required-marker {
      color: $coral-red;
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch {
      border-color: $coral-red !important;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            @include setFont(14px, 400, 17px, $coral-red);

            &::placeholder {
              color: $coral-red;
            }
          }
        }
      }
    }

    .mat-button-wrapper {
      color: $coral-red !important;
    }

    mat-icon {
      color: $coral-red !important;
    }
  }

  &:has([disabled]),
  &:has([ng-reflect-disabled="true"]) {
    mat-label,
    .mat-mdc-form-field-required-marker {
      color: $cool-grey;
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch {
      border-color: $american-blue !important;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            @include setFont(14px, 400, 17px, $cool-grey);

            &::placeholder {
              color: $cool-grey;
            }
          }
        }
      }
    }

    .mat-button-wrapper {
      color: $american-blue !important;
    }

    mat-icon {
      color: $american-blue !important;
    }
  }

  .icon-gray {
    color: #3A4065;
  }

  .icon-white {
    color: #E7F3F3;
  }

  .mat-form-field-underline {
    background-color: transparent;
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch {
    border-color: #3A4065 !important;
  }

  .mdc-notched-outline__leading {
    border-top-left-radius: $input-radius !important;
    border-bottom-left-radius: $input-radius !important;
  }

  .mdc-notched-outline__trailing {
    border-top-right-radius: $input-radius !important;
    border-bottom-right-radius: $input-radius !important;
  }

  .mat-mdc-form-field-error-wrapper {
    z-index: 1;
    background: #191d32;
    padding: 8px 16px !important;
    border-radius: 10px;
    top: 5px;
    border: 1px solid #3A4065 !important;
  }

  &.icon-left {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          margin-left: 10px !important;
        }
      }
    }
  }

  &__datepicker-gray {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            &::placeholder {
              color: $bright-gray;
            }

            @include setFont(14px, 400, 17px, #E7F3F3);
          }
        }

        .mat-form-field-outline {
          background-color: $yankee-blue;
          border-radius: 8px;
        }
      }
    }
  }

  &__datepicker {
    .mat-mdc-text-field-wrapper {
      background-color: #191D32;
      border-radius: 8px;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            &::placeholder {
              color: $bright-gray;
            }

            @include setFont(14px, 400, 17px, #E7F3F3);
          }
        }

        .mat-form-field-outline {
          background-color: $yankee-blue;
          border-radius: 8px;
        }
      }
    }

    .mat-datepicker-toggle {
      button {
        background: transparent;
        color: #E7F3F3;
      }
    }
  }

  &__input {
    .mat-mdc-text-field-wrapper {
      background-color: #191D32;
      border-radius: $input-radius;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
          }

          input:-webkit-autofill {
            -webkit-text-fill-color: #E7F3F3 !important;
          }

          input {
            &::placeholder {
              color: $bright-gray;
            }

            @include setFont(14px, 400, 17px, #E7F3F3);
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }

  &__select {
    .mat-mdc-text-field-wrapper {
      background-color: #191D32;
      border-radius: $input-radius;
    }

    mat-select {
      .mat-mdc-select-trigger {
        .mat-mdc-select-value {
          @include setFont(14px, 400, 17px, #E7F3F3);
        }

        .mat-mdc-select-arrow-wrapper {
          .mat-mdc-select-arrow {
            color: #E7F3F3;
          }
        }
      }
    }
  }

  &__select-gray {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          input {
            &::placeholder {
              color: $bright-gray;
            }

            @include setFont(14px, 400, 17px, #E7F3F3);
          }
        }

        .mat-form-field-outline {
          background-color: $yankee-blue;
          border-radius: $input-radius;
        }
      }
    }

    mat-select {
      .mat-mdc-select-trigger {
        .mat-mdc-select-value {
          @include setFont(14px, 400, 17px, #E7F3F3);

          .mat-mdc-select-placeholder {
            @include setFont(14px, 400, 17px, #E7F3F3);
          }
        }

        .mat-mdc-select-arrow-wrapper {
          .mat-mdc-select-arrow {
            color: #E7F3F3;
          }
        }
      }
    }
  }

  &__radio {
    margin: 8px 0 16px 0;

    .mat-mdc-radio-button {
      margin-right: 16px;

      .mdc-form-field {
        .mdc-radio {
          .mdc-radio__background {
            .mdc-radio__outer-circle,
            .mdc-radio__inner-circle {
              border-color: $lilac-sky !important;
            }
          }
        }

        label {
          color: $white;
        }
      }
    }
  }
}

.mat-mdc-select-panel {
  background: $yankee-blue !important;

  .mat-mdc-option {
    background: transparent !important;
    border-bottom: 1px solid $dark-gunmetal;

    &:last-child {
      border-bottom: none;
    }

    .mdc-list-item__primary-text {
      color: $bright-gray !important;
    }
  }
}

.mat-mdc-paginator {
  background: transparent !important;
  font-size: 14px;

  .mat-mdc-paginator-container {
    justify-content: start;
    flex-wrap: wrap;


    @include screen('laptop', 'max') {
      gap: 16px;
      justify-content: center;
    }

    .mat-mdc-paginator-page-size-label {
      color: $bright-gray;
      font-size: 14px;
    }

    mat-form-field {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: transparent !important;
      }

      mat-select {
        .mat-mdc-select-value-text {
          font-size: 14px;
          color: $american-blue !important;
        }

        .mat-mdc-select-arrow {
          color: $american-blue !important;
        }
      }
    }

    .mat-mdc-paginator-range-actions {
      font-size: 14px !important;
      color: $american-blue !important;

      button {
        color: $american-blue !important;

        &[disabled] {
          opacity: 0.3;
        }

        svg {
          fill: currentColor !important;
        }
      }
    }
  }
}