@use 'variables' as *;

@mixin screen($brekpoint, $min-max: 'min') {
    @if map-has-key($map: $breakpoints, $key: $brekpoint) {
        @if $min-max != "max" {
            @media only screen and (min-width: map-get($breakpoints, $brekpoint)) {
                @content;
            }
        } @else {
            @media only screen and (max-width: map-get($breakpoints, $brekpoint)) {
                @content;
            }
        }
    } @else {
        @warn "`#{$brekpoint}` can not be found. "
        + "Available breakpoins are: #{map-keys($breakpoints)}.";
    }
}

@mixin setFont($fs: 16px, $fw: normal, $lh: normal, $color) {
    color: $color;

    @if $fs !=16px {
        font-size: $fs;
    }

    @if $fw !=normal {
        font-weight: $fw;
    }

    @if $lh !=normal {
        line-height: $lh;
    }
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto, $zindex: auto) {
    position: absolute;

    @if $top !=auto {
        top: $top;
    }

    @if $right !=auto {
        right: $right;
    }

    @if $bottom !=auto {
        bottom: $bottom;
    }

    @if $left !=auto {
        left: $left;
    }

    @if $zindex !=auto {
        z-index: $zindex;
    }
}

@mixin fixed($top: auto, $right: auto, $bottom: auto, $left: auto, $zindex: auto) {
    @include absolute($top, $right, $bottom, $left, $zindex);
    position: fixed;
}

@mixin size($width: auto, $height: auto) {
    width: $width;
    height: $height;
}

@mixin square($size: auto) {
    @include size($size, $size);
}

@mixin truncate($edge: false) {

    @if $edge {
        max-width: $edge;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
