// -- Default

$font-family: 'Montserrat', sans-serif;
$font-size: 16px;
$font-weight: normal;

// -- Colors

$french-fuchsia: #FB3896;
$maximum-yellow: #FBF338;
$orange: #fb7e38;
$turquoise: #62A4D6;
$purple: #BD38FB;
$guppie-green: #00FF66;
$blue-violet: #7738FB;
$menthol: #DAFFA5;
$coral-red: #FB3838;
$white: #ffffff;
$blue: #2B40B0;
$lilac-sky: #A7A1F8;
$black: #0E1122;
$grey: #70748C;

// -- Dark grey tones

$bright-gray: #E7F3F3;
$vampire-black: #070707;
$chinese-black: #0E1121;
$dark-gunmetal: #191D32;
$yankee-blue: #1F243E;
$american-blue: #3A4065;

// -- Purple tones

$midnight: #0E0B29;
$plum-night: #1A1841;
$purple-dawn: #3D3774;
$purple-days: #847bdd;
$lilac-sky: #A7A1F8;
$light-mist: #C2C2FF;

// -- Gradients

$primary-gradient: linear-gradient(90deg, $french-fuchsia 0%, $purple 100%);;
$secondary-gradient: linear-gradient(90deg, $maximum-yellow 0%, $french-fuchsia 100%);
$default-gradient: linear-gradient(90deg, $maximum-yellow 0%, $blue-violet 100%);
$tertiary-gradient: linear-gradient(90deg, $maximum-yellow 0%, $blue-violet 100%);
$tertiary-gradient-transparent: linear-gradient(90deg, #262724 0%, #2B2646 100%);


// -- Breakpoints
$breakpoints: (
        'large-desktop': 1440px,
        'desktop': 1280px,
        'laptop': 1023px,
        'tablet': 768px,
        'phablet': 600px,
        'mobile': 320px
)!default;

// DEPRECATED

$error: #fb3838;
$success: #00ff66;
$primary: #0070f0;
$primary-dark: #0f2a61;
$cool-grey: #828497;
$light-grey: #798088;
$bg-color: #f8f8f8;

$grey-border: #e4e4e4;

$backdrop: rgba(0, 0, 0, 0.55);

$container-width: 1080px;
$side-menu-width: 200px;
$side-menu-width-minimize: 96px;
$side-menu-mobile-width: 272px;
$more-games-sidebar-width: 260px;
$rewards-bar-width: 270px;

$stacking-context: (veiled: -9, portal: 42, header: 200, modal: 201, backdrop: 202, overlay: 301, dropdown: 302, tooltip: 401, loader: 501);